import {apiPrivate} from "@ldcc/client/src/utils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ISettings} from "./index.interface";
import axios from "axios";

const PATH = '/es';
const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL + '/v1' : '/v1';// keep to code on local after after complete hard '/v1' on code use  baseURL bellow

const createApi = (filterAt:string)=>{
    return axios.create({
        baseURL: baseURL,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Filter: `{"where":{"key":{"regexp":"^${filterAt}.","options":"i"}},"limit":50,"offset":0,"order":"","fields":["key","type","value"]}`
        },
    });
}
export const getSuggestionSettings = createAsyncThunk(
    'api/get-list-suggestion',
    async () => {
        let api = createApi("suggestion")
        const res = await api.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getIndexSettings = createAsyncThunk(
    'api/get-list-index',
    async () => {
        let api = createApi("index")
        const res = await api.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getProductSettings = createAsyncThunk(
    'api/get-list-product',
    async () => {
        let api = createApi("product")
        const res = await api.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getSalesSettings = createAsyncThunk(
    'api/get-list-sale',
    async () => {
        let api = createApi("sales")
        const res = await api.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getMagentoSettings = createAsyncThunk(
    'api/get-list-magento',
    async () => {
        let api = createApi("magento")
        const res = await api.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getSettings = createAsyncThunk(
    'api/get-list-setting',
    async () => {
        const res = await apiPrivate.get(`${PATH}/search-settings`);
        return res.data;
    }
)
export const getCountSettings = createAsyncThunk(
    'api/get-count-settings',
    async () => {
        const res = await apiPrivate.get(`${PATH}/search-settings/count`);
        return res.data;
    }
)
export const deleteSettings = createAsyncThunk(
    'api/delete-settings',
    async (id: string) => {
        const res = await apiPrivate.delete(`${PATH}/search-settings/${id}`);
        return res.data;
    }
)
export const getSetting = createAsyncThunk(
    'api/get-setting',
    async (id: string) => {
        const res = await apiPrivate.get(`${PATH}/search-settings/${id}`);
        return res.data;
    }
)
export const editSetting = createAsyncThunk(
    'api/edit-setting',
    async (data: ISettings) => {
        const res = await apiPrivate.put(`${PATH}/search-settings`, data);
        return res.data;
    }
)


