import React from "react";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button } from "antd";

interface IProps {
    data: Array<any>;
    fileName?: string;
}

export const ExportCSV: React.FC<IProps> = ({ data, fileName }) => {
    return (
        <Button type="link" icon={<DownloadOutlined /> } style={{borderBottom: '1px solid #109CF1', padding: '0px'}} >
            <CSVLink data={data || []} filename={`${fileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}`}>
                {` Export Data(CSV)`}
            </CSVLink>
        </Button>
    );
};

export const ExportExcel: React.FC<IProps> = ({ data, fileName }) => {
    const handleExportExcel = (data: Array<any>, fileName: string) => {
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Transactions");
        XLSX.writeFile(wb, `${fileName}-${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
    }
    return (
        <Button type="link" icon={<DownloadOutlined />} style={{borderBottom: '1px solid #109CF1', padding: '0px'}} onClick={() => handleExportExcel(data, fileName)}> 
            Export Data(xlsx)
        </Button>
    );
};
