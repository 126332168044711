import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import type { Moment } from 'moment';
import { RangePickerProps } from "antd/lib/date-picker"
const { RangePicker: BaseRangePicker }: any = DatePicker
type RangeValue = [Moment | null, Moment | null] | null;

interface IProps {
  handleChangeDate?: (from: Moment, to: Moment) => void;
  disabledMonth?: boolean
}

export const RangePicker: React.FC<RangePickerProps & IProps> = ({ handleChangeDate, disabledMonth = false }) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const handleDisabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 2;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') > 2;
    return !!tooEarly || !!tooLate || current.isAfter(moment());
  };
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const onChange = (event) => {
    handleChangeDate(event[0], event[1])
  }
  return (
    <BaseRangePicker
      defaultValue={[moment().add(-1, 'months'), moment()]}
      // value={dates || value}
      disabledDate={disabledMonth ? handleDisabledDate : disabledMonth}
      onCalendarChange={val => setDates(val)}

      onOpenChange={onOpenChange}
      onChange={onChange}
    />
  )
}

