import { TransFormFromTextToLocalString, queryListString } from '@ldcc/client/src/utils';
import { apiPrivate } from '@ldcc/client/src/utils';
import { EndpointProjects } from '@ldcc/client/src/utils/constant';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPaginationTransactions, IRequestTransactions } from './index.interface';
import { ITransactionResponse } from '../../interface';
import moment from 'moment';
import { argsList } from '@ldcc/client/src/redux/interface';
import pLimit from 'p-limit';
import { TypeTransaction } from '../../../utils';
const limit = pLimit(7);

export const projectTransactions = createAsyncThunk(
    'project/me/transactions',
    async (request: IRequestTransactions, thunkApi) => {
        try {
            const data = await getDataToExportTransactions(request);
            return data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);
export const getDataToExportTransactions = async (request) => {
    let query = '';
    switch (request.type) {
        case TypeTransaction.Refund:
            query = `${EndpointProjects.projects}/${request.projectId}/payments/refund/export`;
            break;
        case TypeTransaction.FirmBanking:
            query = `${EndpointProjects.projects}/${request.projectId}/firm-banking/transaction/export`;
            break;
        default:
            query = `${EndpointProjects.projects}/${request.projectId}/payments/export`
            break;
    }
    let fromDateClone = moment(request.from).startOf('day');
    let toDateClone = moment(request.to);
    let promises = [];
    while (fromDateClone <= toDateClone) {
        const queryString = queryListString(query, { ...request, pagination: undefined, from: fromDateClone, to: moment(fromDateClone).endOf('day') }) + (request?.selects ?? '')
        const promise = limit(() => apiPrivate.get(queryString));
        promises.push(promise);
        fromDateClone.add(1, "days");
    }

    const data = await Promise.all(promises);
    const result: Array<ITransactionResponse> = data.map(items => {
        return items.data.map(item => {
            return {
                ...item,
                createdAt: TransFormFromTextToLocalString(item.createdAt),
                updatedAt: TransFormFromTextToLocalString(item.updatedAt),
            }
        })
    })
    return result.flat().sort((a, b) => a.createdAt?.localeCompare(b?.createdAt));
}

export const projectTransactionsWithPagination = createAsyncThunk(
    'project/me/transactionsWithPagination',
    async (request: argsList, thunkApi) => {
        const query = `${EndpointProjects.projects}/${request.projectId}/payments`;
        const queryString = queryListString(query, request)

        try {
            const data: IPaginationTransactions = (await apiPrivate.get(queryString)).data;
            return {
                data: data?.items,
                dataExport: [],
                total: data?.total
            };
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const projectRefundTransactionsWithPagination = createAsyncThunk(
    'project/me/refundTransactionsWithPagination',
    async (request: argsList, thunkApi) => {
        const query = `${EndpointProjects.projects}/${request.projectId}/payments/refund/all`;
        const queryString = queryListString(query, request)

        try {
            const data: IPaginationTransactions = (await apiPrivate.get(queryString)).data;
            return {
                data: data?.items,
                dataExport: [],
                total: data?.total
            };
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const projectFirmBankingTransactionsWithPagination = createAsyncThunk(
    'project/me/firmBankingTransactionsWithPagination',
    async (request: argsList, thunkApi) => {
        const query = `${EndpointProjects.projects}/${request.projectId}/firm-banking`;
        const queryString = queryListString(query, request)

        try {
            const data: IPaginationTransactions = (await apiPrivate.get(queryString)).data;
            return {
                data: data?.items,
                dataExport: [],
                total: data?.total
            };
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const firmBankingTransactions = createAsyncThunk(
    'project/me/firm-banking-transactions',
    async (request: IRequestTransactions, thunkApi) => {
        try {
            const data = await getDataToExportTransactions(request);
            return data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);