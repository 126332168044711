import { notification, message } from 'antd';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { login, loginWithMailMultiFactor, LoginWithTOTPMultiFactor, logout } from "./index.action";
const initialState = {
  loading: false,
  user: null
};
//slice
const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //login
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
      state.user = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.user = null;
      notification.error({
        message: 'Oop!',
        description: action.payload,
      });
    })
    //login with email multifactor
    builder.addCase(loginWithMailMultiFactor.pending, (state, action) => {
      state.loading = true;
      state.user = null;
    });
    builder.addCase(loginWithMailMultiFactor.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(loginWithMailMultiFactor.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.user = null;
    })
    //login with totp multifactor
    builder.addCase(LoginWithTOTPMultiFactor.pending, (state, action) => {
      state.loading = true;
      state.user = null;
    });
    builder.addCase(LoginWithTOTPMultiFactor.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(LoginWithTOTPMultiFactor.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.user = null;
      notification.error({
        message: 'Unauthorize',
        description: action.payload
      })
    })
    //logout
    builder.addCase(logout.pending, (state, action) => {
      state.loading = true;
      state.user = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loading = false;
      state.user = {};
      window.location.replace('/login');
    });
    builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    })
  }
});

export default AuthSlice.reducer;
