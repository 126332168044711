import React from "react";
import { Button, Form, Input, notification } from 'antd';
import { register } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ldcc/client/src/redux/reducers";
import { Link } from "react-router-dom";
import { FormInputPassword } from "@ldcc/client/src/components";

interface Props {
    isSUDO?: boolean,
}
const registerContainer: React.FC<Props> = ({isSUDO = false}) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state: RootState) => state.register);
    const onFinish = async (values: any) => {
        const { name, email, password, firstPassword } = values;
        if (!firstPassword || !password) {
            notification.error({
                message: 'Oops!',
                description: 'Information field not null',
            });
        }
        if (firstPassword === password) {
            const registerData = {
                name, email, password
            };
            dispatch(register(registerData))
        } else {
            notification.error({
                message: 'Error!',
                description: 'The two passwords do not match',
            });
        }

    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!', type: "email" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="firstPassword"
                label="Password"
                rules={[{ required: true, message: 'Please input your password!' }]}

            >
                <Input.Password />
            </Form.Item>
            <FormInputPassword
                label="Confirm"
                name="password"
                placeholder="Confirm Password"
            />
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Register
                </Button>
                {
                    isSUDO ? <></> :<>Or <Link to="/login">Login</Link></> 
                }
            </Form.Item>
        </Form>
    );

}

export default registerContainer;