import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSearchTermList, getCountSearchTerm} from "../search-terms/index.action";

const initialState = {
    loading: false,
    error: null,
    searchTerms: [],
    count:null
};

const SearchTerrmsSlice = createSlice({
    name: "searchTerms",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getSearchTermList.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getSearchTermList.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.searchTerms = action.payload
        });
        builder.addCase(getSearchTermList.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //getCount
        builder.addCase(getCountSearchTerm.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getCountSearchTerm.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.count = action.payload
        });
        builder.addCase(getCountSearchTerm.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
    }
})

export default SearchTerrmsSlice.reducer