import React from 'react'
import { Form, notification, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ldcc/client/src/redux/reducers';
import { IProfileChangePassword } from '../redux/interface';
import { profileChangePassword } from "../redux/actions";
import { FormInputPassword } from '@ldcc/client/src/components';

const ChangePassword: React.FC = () => {

    const { data, loading } = useSelector((state: RootState) => state.profile);
    const dispatch = useDispatch();

    const onFinishPassWord = async (values: any) => {
        if(!values.currentPassword || !values.password) {
            notification.error({
                message: 'Oops!',
                description: 'Information field not null',
            });
        }else {
            const dataChangePassWord: IProfileChangePassword = {
                id: data?.id,
                currentPassword: values.currentPassword,
                newPassword: values.password
    
            }
            dispatch(profileChangePassword(dataChangePassWord))
        }
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinishPassWord}
        >
            <Form.Item
                name="currentPassword"
                label="Current PassWord"
            >
                <Input.Password />
            </Form.Item>
            <FormInputPassword 
                label="Password"
                name="password"
            />
            <Form.Item>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Change
                </Button>
            </Form.Item>
        </Form >
    )
}

export default ChangePassword