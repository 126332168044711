import React from "react";
import { Form, Input, Button, notification} from 'antd';
import { api } from "@ldcc/client/src/utils";
import { EndpointAuth } from "@ldcc/client/src/utils/constant";

const verifyEmailContainer: React.FC = () => {
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values: any) => {
        const { email } = values;
        try {
            await api.post(EndpointAuth.emailVerification, {
                email,
            });
            notification.success({
                message: "Verify Email",
                description: "Please check your email",
            });
            setLoading(false)
        } catch (e) {
            notification.error({
                message: "Oops!",
                description: e,
            });
            setLoading(false)
        }
    };
    
    return (
        <Form
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!', type: "email" }]}

            >
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Verify
                </Button>
            </Form.Item>
        </Form>
    )
}
export default verifyEmailContainer;