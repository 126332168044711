import React from "react";
import { Space, Form, Input, Button, Alert } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ldcc/client/src/redux/reducers";
import { profileUpdate } from "../redux/actions";
import { IProfileUpdate } from "../redux/interface";
import { IDataSelect, Select } from "@ldcc/client/src/components";

const style: React.CSSProperties = { paddingTop: 35 };
const userProfileContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state: RootState) => state.profile);
    const [form] = Form.useForm();
    const genderFields: Array<IDataSelect> = [
        {
            value: "MALE",
            data: "MALE",
        },
        {
            value: "FEMALE",
            data: "FEMALE",
        },
        {
            value: "ANOTHER",
            data: "ANOTHER",
        },
    ];

    const onFinish = async (values: any) => {
        const dataUpdateProfile: IProfileUpdate = {
            id: data?.id,
            name: values.name,
            gender: values.gender
        }
        dispatch(profileUpdate(dataUpdateProfile))
    }

    React.useEffect(() => {
        form.setFieldsValue({
            name: data?.name,
            gender: data?.gender
        });
    }, [data, form]);

    const renderWarning = () => {
        if (data.twoFactorMethod && data.twoFactorMethod === "NONE") {
            return (
              <Alert
                message="Warning"
                description="Two-factor authentication(2FA) is not enabled for this profile. Please enable it for better security."
                type="warning"
                showIcon
              />
            );
        }
        return null;
    };

    return (
      <div style={style}>
          {renderWarning()}
          <Form form={form}
                layout="vertical"
                onFinish={onFinish}
          >
              <Space direction="vertical">
                  <Form.Item label="Name" name="name">
                      <Input />
                  </Form.Item>
                  <Form.Item name="gender">
                      <Select label="Gender" data={genderFields}></Select>
                  </Form.Item>
              </Space>
              <Form.Item wrapperCol={{ span: 12 }}>
                  <Button type="primary" block htmlType="submit" loading={loading}>
                      Change
                  </Button>
              </Form.Item>
          </Form>
      </div>
    )
}
export default userProfileContainer;
