import { notification } from 'antd';
import { IProject } from './project.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DescriptionsNotification, MessageNotifiCation } from '@ldcc/client/src/utils/constant';
import  ProjectsServices  from './project.service';

const projectInit: IProject = {
    id: '',
    name: '',
    attributes: '',
    autoJoinDomain: false,
    forceTwoFactor: false,
    ipRestrictions: '',
    onlyAllowDomain: false,
    profilePictureUrl: '',
    createdAt: '',
    updatedAt: ''
}

const initialState = {
    loading: false,
    success: false,
    project: projectInit,
};

const ProjectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(ProjectsServices.getProjectAction.pending, (state, action) => {
            state.project = null;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(ProjectsServices.getProjectAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.project = action.payload;
            notification.success({
                message: MessageNotifiCation.SUCCESSFULLY,
                description: DescriptionsNotification.CHANGE_PROJECT,
            });
        });
        builder.addCase(ProjectsServices.getProjectAction.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.project = null;
            state.success = false;
        });
    }
});

export default ProjectSlice.reducer;