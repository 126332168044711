// import { notification } from 'antd';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApikey } from './api-keys.interface';
// import ApikeyServices from './api-keys.services';

const keys: Array<IApikey> = [];
const key: IApikey | null = null
const initialState = {
    loading: false,
    scopes: {},
    keys: keys,
    key: key

}
const ApiKeyScopes = createSlice({
    name: "ApiKeys",
    initialState,
    reducers: {},
    // extraReducers(builder) {
    //     builder.addCase(ApikeyServices.getApiKeyScopesAction.pending, (state, action) => {
    //         state.scopes = {};
    //         state.loading = true;
    //     });
    //     builder.addCase(ApikeyServices.getApiKeyScopesAction.fulfilled, (state, action ) => {
    //         state.loading = false;
    //         state.scopes = action.payload;
    //     });
    //     builder.addCase(ApikeyServices.getApiKeyScopesAction.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //         state.scopes = null;
    //     });
    //     //keys
    //     // TODO: get All
    //     builder.addCase(apiKeys.pending, (state, action) => {
    //         state.keys = [];
    //         state.loading = true;
    //     });
    //     builder.addCase(apiKeys.fulfilled, (state, action ) => {
    //         state.loading = false;
    //         state.keys = action.payload;
    //     });
    //     builder.addCase(apiKeys.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //         state.keys = [];
    //     });
    //     // TODO: get
    //     builder.addCase(getApikey.pending, (state, action) => {
    //         state.loading = true;
    //     });
    //     builder.addCase(getApikey.fulfilled, (state, action ) => {
    //         state.loading = false;
    //         state.key = action.payload;
    //     });
    //     builder.addCase(getApikey.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //         state.key = null;
    //     });
    //     // TODO: create
    //     builder.addCase(createApiKey.pending, (state, action) => {
    //         state.loading = true;
    //     });
    //     builder.addCase(createApiKey.fulfilled, (state, action: PayloadAction<IApikey> ) => {
    //         state.loading = false;
    //         state.keys = [
    //             ...state.keys,
    //             action.payload
    //         ]
    //     });
    //     builder.addCase(createApiKey.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //     });
    //     // TODO: update
    //     builder.addCase(updateApikey.pending, (state, action) => {
    //         state.loading = true;
    //     });
    //     builder.addCase(updateApikey.fulfilled, (state, action: PayloadAction<IApikey> ) => {
    //         state.loading = false;
    //         const index = state.keys.findIndex((key) => key?.id === action.payload.id)
    //         state.keys[index] = action.payload
    //         notification.success({
    //             message: 'Successfully',
    //             description: 'Update Api Key successfully',
    //         });
    //     });
    //     builder.addCase(updateApikey.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //     });
    //     // TODO: delete
    //     builder.addCase(deleteApikey.pending, (state, action) => {
    //         state.loading = true;
    //     });
    //     builder.addCase(deleteApikey.fulfilled, (state, action: PayloadAction<IApikey> ) => {
    //         state.loading = false;
    //         notification.success({
    //             message: 'Successfully',
    //             description: 'Delete Api Key successfully',
    //         });
    //     });
    //     builder.addCase(deleteApikey.rejected, (state, action: PayloadAction<any>) => {
    //         state.loading = false;
    //     });
    // }
});

export default ApiKeyScopes.reducer;