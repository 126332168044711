import React from "react";
import { Loading } from "components";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginWithMailMultiFactor } from "@ldcc/auth/src/redux/actions";
import { RootState } from "redux/reducers";


const Login: React.FC = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');
    const dispatch = useDispatch();
    const { user, loading } = useSelector((state: RootState) => state.auth);
    React.useEffect(() => {
        dispatch(loginWithMailMultiFactor(token));
        if(user?.id) {
            navigate('/projects')
        }
    }, [dispatch, navigate, token, user?.id]);
    return <Loading loading={loading} />;
};

export default Login;
