import React, { useEffect } from 'react'
import { Button, Table as BaseTable } from 'antd'
import type { TableProps } from 'antd/es/table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { useDispatch } from 'react-redux';
import style from './style.module.less'
import { RangePicker } from 'components/range-picker';
import { GetPermissionByProjectId, HasPermission, PermissionScreens, getSortOrderOfAntd } from 'utils';
import moment from 'moment';
import { argsList } from 'redux/interface';
import { SearchInput } from 'components/input/search';
import type { SelectProps } from 'antd';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from '@ant-design/icons';
import { getDataToExportTransactions } from '@ldcc/project/src/redux/slices/project-transactions/index.action';
import { Moment } from 'moment'
interface ITableProps extends TableProps<DefaultRecordType> {
    search?: SelectProps['options'];
    titleTable?: string;
    rangePicker?: boolean;
    onDataTable?: {
        functionName: Function,
        args?: any;
    }
    actions?: {
        del?: {
            functionName: Function,
            args?: {};
        };
    },
    total?: number;
    onExport?: boolean
}
export const Table: React.FC<ITableProps> = ({ search, onDataTable, rangePicker, actions, titleTable, total, onExport = true, ...rest }) => {
    const dispatch = useDispatch();
    const functionListTable = onDataTable.functionName;
    const [argsList, setArgsList] = React.useState<argsList>({
        ...onDataTable.args,
        ...(!!rangePicker && {
            from: moment().add(-1, 'months'),
            to: moment(),

        }),
        pagination: {
            skip: 0,
            take: 10
        }
    })
    const [pageNumber, setPageNumber] = React.useState(1)
    const [loadingExport, setLoadingExport] = React.useState(false)

    const handleChangeDate = (from: Moment, to: Moment) => {
        setArgsList({
            ...argsList,
            from: from,
            to:to,
            pagination: {
                skip: 0,
                take: argsList.pagination.take
            }
        })
        setPageNumber(1)
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        switch (extra.action) {
            case 'filter':
                setArgsList({
                    ...argsList,
                    filters: {
                        ...filters
                    },
                    pagination: {
                        take: pagination.pageSize,
                        skip: 0
                    }
                })
                setPageNumber(1)
                break;
            case 'sort':
                setArgsList({
                    ...argsList,
                    sorter: {
                        orderBy: sorter['field'],
                        order: getSortOrderOfAntd[sorter['order']],
                    }
                })
                break;
            case 'paginate': {
                
                setPageNumber((argsList.pagination.take !== pagination.pageSize) ? 1 : pagination.current)
                setArgsList({
                    ...argsList,
                    pagination: {
                        take: pagination.pageSize,
                        skip: (argsList.pagination.take !== pagination.pageSize) ? 1 : pagination.current
                    }
                })
                break;
            }
            default:
                break;

        }
    };
    const handleSearch = (selectValue, searchText) => {

        // eslint-disable-next-line no-sequences
        const search = selectValue.reduce((acc, curr) => (acc[curr] = [' contains ' + searchText], acc), {})
        setArgsList({
            ...argsList,
            search: {
                ...(!!searchText && { ...search })
            },
            pagination: {
                skip: 0,
                take: argsList.pagination.take
            }
        })
        setPageNumber(1)
    }
   
    const handleExport = async() => {
        setLoadingExport(true)
        try {
            const result = await getDataToExportTransactions(argsList);
            exportData(result)
        }catch {
            setLoadingExport(false)
        }
        

    };
const exportData = (data) => {
    const result = data.map(item => {
        return {
            ...item,
            requestBody: JSON.stringify(item?.requestBody, null, "\t"),
            responseBody: JSON.stringify(item?.responseBody, null, "\t")
        }
    })
    const ws = XLSX.utils.json_to_sheet(result);
    const columnWidth = { wpx: 150 };
    const columns = Object.keys(data[0]);
    const columnProps = columns.map(_ => columnWidth);
    ws["!cols"] = columnProps;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(
        wb,
        `Reports-${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
    setLoadingExport(false)
};

    useEffect(() => {
        dispatch(functionListTable(argsList))
        //Bug
    }, [argsList, dispatch, functionListTable])
    // console.log(columnsTable)
    return (
        <div>
            {
                rangePicker && <div className={style.header}>
                    <span className={style.left} >{titleTable}</span>
                    <RangePicker
                        handleChangeDate={handleChangeDate}
                        disabledMonth={true}
                    />
                </div>
            }

            <div className={style['table-operations']}>
                
                {
                    search &&
                    <SearchInput
                        options={search}
                        size="middle"
                        handleSearch={handleSearch}
                    />
                }
                {
                    HasPermission(GetPermissionByProjectId(onDataTable.args.projectId, PermissionScreens.READ_REPORTS_TRANSACTION)) && onExport && <Button type="link" style={{marginLeft: '10px'}} loading={loadingExport} icon={<DownloadOutlined />} onClick={handleExport}> Export Data(xlsx)</Button>
                }
                {
                    <Button type='text' style={{ width: '20%', display: 'flex', justifyContent: 'right' }}><strong>Total Item</strong>: {total.toLocaleString()}</Button>
                }
            </div>
            <BaseTable
                onChange={onChange}
                scroll={{ x: 1046 }}
                pagination={{ total, current: pageNumber }}
                {...rest}
            />
        </div>


    )
};

