import React, {useEffect, useState} from "react";
import {Button, Col, Input, notification, Row} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@ldcc/client/src/redux/reducers";
import {ErrorDispatching} from "@ldcc/client/src/redux/interface";
import {getCountSettings, getSettings} from "../../redux/slices/settings/index.action";
import {ISettings} from "../../redux/slices/settings/index.interface";
import TableSettingComponent from "./table-setting-component";


const {Search} = Input
const AllList = () => {
    const dispatch = useDispatch();
    const {settings}: any = useSelector((state: RootState) => state.settings);
    const {countSettings}: number | any = useSelector((state: RootState) => state.settings);

    const [dataSource, setDataSource] = useState(settings)


    useEffect(() => {
        if (!countSettings) {
            getCountSetting().then(() => getListSettings());
        }
        if (settings) {
            setDataSource(settings)
        }

    }, [countSettings, settings]);
    const getCountSetting = async () => {
        const status = await dispatch(getCountSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListSettings = async () => {
        let status = await dispatch(getSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const onReloadCountClick = () => {
        getCountSetting().then(() => getListSettings());
    }
    const onSearch = (value: string) => {
        filerDataSource(value)
    };
    const onSearchChange = (value: any) => {
        filerDataSource(value.target.value)
    };

    const filerDataSource = (searchValue: string) => {
        let newDataSource = settings.reduce((res: Array<ISettings>, item: ISettings) => {
            if (item.key && item.key.includes(searchValue))
                res.push(item)
            return res
        }, [])
        setDataSource(newDataSource)
    }

    return (
        <div>
            <Row>
                <Col span={4}>
                    <Button icon={<ReloadOutlined/>}
                            onClick={onReloadCountClick}> {countSettings ? countSettings.count : 0} </Button>
                </Col>
                <Col span={12}>
                    <Search key="search" placeholder="Search" allowClear enterButton
                            onChange={(v) => onSearchChange(v)}
                            onSearch={onSearch}/>
                </Col>
            </Row>
            <TableSettingComponent dataSource={dataSource}/>
        </div>
    )
}
export default AllList;