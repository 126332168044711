import Cookie from 'universal-cookie';

const cookie = new Cookie();
const mandatory = (): any => {
	throw new Error('Storage Missing parameter!');
};

export default class Storage {
	private name:string;

	private options = {};

	constructor(name = mandatory(), value = {}, options = {}) {
		this.name = name;
		this.options = options;

		if (!this.value) {
			this.value = value;
		}
	}

	set value(value) {
		cookie.set(
			this.name,
			value,
			{
				path: '/',
				maxAge: 365 * 24 * 60 * 60,
				...this.options,
			},
		);
	}

	get value() {
		return cookie.get(this.name);
	}

	// eslint-disable-next-line class-methods-use-this
	get allCookies() {
		return cookie.getAll();
	}

	destroy = (next = f => f) => {
		cookie.remove(
			this.name,
			{
				path: '/',
				maxAge: 365 * 24 * 60 * 60,
				...this.options,
			},
		);
	}
}
