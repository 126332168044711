import React from "react";
import {Col, Divider, Modal, notification, Row, Space, Table} from 'antd';
import {useNavigate} from "react-router-dom";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleFilled} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {
    deleteSettings,
    getCountSettings,
    getIndexSettings, getMagentoSettings, getProductSettings, getSalesSettings, getSettings,
    getSuggestionSettings
} from "../../redux/slices/settings/index.action";
import {ErrorDispatching} from "@ldcc/client/src/redux/interface";

const {confirm} = Modal;
const TableSettingComponent = (props:any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showDeleteConfirm = (key: string) => {
        confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleFilled/>,
            content: 'The action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSetting(key)
                switch (props.tab){
                    case "suggestion":
                        getListSuggestion()
                        return;
                    case "indexing":
                        getListIndexing()
                        return;
                    case "product":
                        getListProduct()
                        return;
                    case "sales":
                        getListSales()
                        return;
                    case "magento":
                        getListMagento()
                        return;
                    default:
                        getListSettings()
                }
                getCountSetting();
            },
            onCancel() {
            },
        });
    };
    const getCountSetting = async () => {
        const status = await dispatch(getCountSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const deleteSetting = async (key: string) => {
        const status = await dispatch(deleteSettings(key)) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListSuggestion = async () => {
        let status = await dispatch(getSuggestionSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListIndexing = async () => {
        let status = await dispatch(getIndexSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListProduct = async () => {
        let status= await dispatch(getProductSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListSales = async () => {
        let status = await dispatch(getSalesSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListMagento = async () => {
        let status = await dispatch(getMagentoSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListSettings = async () => {
        let status = await dispatch(getSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (_: any, item: any) => <a
                onClick={() => navigate(`/search-service/settings/${item.key}/edit`)}>{item.key}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (_: any, item: any) => <a
                onClick={() => navigate(`/search-service/settings/${item.key}/edit`)}>{item.value.toString()}</a>,
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (_: any, item: any) => <Space size="middle">
                <EditTwoTone onClick={() => navigate(`/search-service/settings/${item.key}/edit`)}/>
                <DeleteTwoTone onClick={() => showDeleteConfirm(item.key)}/>
            </Space>
        },
    ];
    return (
        <div>
            <Divider/>
            <Row>
                <Col span={24}>
                    <Table dataSource={props.dataSource} columns={columns}
                           rowKey={(record) => record.key}
                           pagination={{
                               pageSize: 50,
                               pageSizeOptions: ['50', '100', '200', '500'],
                               position: ['bottomCenter']
                           }}
                           size="small" style={{overflow: 'auto'}}/>
                </Col>
            </Row>
        </div>
    )
}
export default TableSettingComponent;