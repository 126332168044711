import React from "react";
import { Menu, MenuProps } from "antd";
import {
    DashboardIcon,
    PaymentIcon,
    AlarmIcon,
    LogsIcon,
    BillingsIcon,
    ProjectIcon,
    ApiKeyIcon,
    RoutingIcon,
    PaymentSettingIcon,
    SearchIcon,
    UserIcon,
    RefundIcon,
    OrderIcon,
    UsageReportIcon,
    CatalogIcon,
} from "components";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { GetPermissionByProjectId, HasPermission, PermissionScreens, Role } from "utils";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const SudoMenu: MenuItem[] = [
    {
        label: "SUDO",
        key: "/sudo",
        type: 'group',
        children: [
            {
                label: "Projects",
                key: "/sudo-projects",
                icon: <ProjectIcon />,
            },
            {
                label: "Users",
                key: "/sudo-users",
                icon: <UserIcon />,
            },
            {
                label: "Access Logs",
                key: "/sudo-logs",
                icon: <LogsIcon />,
            },
            {
                label: "Sync Data",
                key: "/sudo-sync-data",
                icon: <RoutingIcon />,
            }
        ],
    },
]


const MenuSidebar: React.FC = () => {
    const location = useLocation();
    const { data } = useSelector((state: RootState) => state.profile)
    const [searchParams] = useSearchParams();

    const projectId = searchParams.get('projectId')
    let items: MenuItem[] = [
        {
            label: 'MANAGEMENT DASHBOARD',
            key: 'pages',
            type: 'group',
            children: [
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_REPORTS_TRANSACTION)) && {
                    label: "Dashboard",
                    key: "/dashboard",
                    icon: <DashboardIcon />,
                },
                {
                    label: "Projects",
                    key: "/project",
                    icon: <ProjectIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_API_KEY)) && {
                    label: "Api Keys",
                    key: "/api-keys",
                    icon: <ApiKeyIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_PROXY)) && {
                    label: "External Routing",
                    key: "/proxy",
                    icon: <RoutingIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_ALERT)) && {
                    label: "Alert Setting",
                    key: "/alert",
                    icon: <AlarmIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.LOGS)) && {
                    label: "Access Logs",
                    key: "/log",
                    icon: <LogsIcon />,
                },
            ].filter(Boolean),
        },
    ];
    const PaymentMenu: MenuItem[] = [
        {
            label: "PAYMENT SERVICES",
            key: "/payment-service",
            type: 'group',
            children: [
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_PAYMENT_PROVIDER)) && {
                    label: "API & services",
                    key: `/services`,
                    icon: <PaymentSettingIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_TRANSACTION)) && {
                    label: "Payment Transactions",
                    key: "/payment-transactions",
                    icon: <PaymentIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_REFUND_TRANSACTION)) && {
                    label: "Refund Payment Transactions",
                    key: "/refund-payment-transactions",
                    icon: <RefundIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_TRANSACTION)) && {
                    label: "Order Transactions",
                    key: "/order-transactions",
                    icon: <OrderIcon />,
                },
            ].filter(Boolean),
        },
    ]
    const ReportMenu: MenuItem[] = [
        {
            label: "REPORT SERVICES",
            key: "/report-service",
            type: 'group',
            children: [
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_REPORTS_BILLING)) && {
                    label: "Billings",
                    key: "/billing",
                    icon: <BillingsIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_REPORTS_SUMMARY)) && {
                    label: "Usage Report",
                    key: "/usage-report",
                    icon: <UsageReportIcon />,
                },
            ].filter(Boolean),
        },
    ]
    const FirmBankingMenu: MenuItem[] = [ // change this
        {
            label: "FIRM-BANKING SERVICES",
            key: "/firm-banking-service",
            type: 'group',
            children: [
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_FIRM_BANKING_CONFIG)) && {
                    label: "Billings",
                    key: "/firm-banking-billing",
                    icon: <BillingsIcon />,
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.READ_FIRM_BANKING_BILLINGS)) && {
                    label: "Bank Transactions", 
                    key: "/firm-banking-bank-transaction",
                    icon: <UsageReportIcon />,
                },
            ].filter(Boolean),
        },
    ]
    const searchMenu: MenuItem[] = [
        {
            label: "SEARCH SERVICES",
            key: "/search-service",
            type: 'group',
            children: [
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)) && {
                    label: 'Catalogs',
                    key: 'catalog',
                    icon: <CatalogIcon />,
                    children: [
                        {
                            label: "Products",
                            key: "/search-service/products",
                        },
                        {
                            label: "Categories",
                            key: "/search-service/categories",
                        }
                    ]
                },
                HasPermission(GetPermissionByProjectId(projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)) && {
                    label: 'Search',
                    key: 'search',

                    icon: <SearchIcon />,
                    children: [
                        {
                            label: "History",
                            key: "/search-service/history",
                        },
                        {
                            label: "Boosting Rules",
                            key: "/search-service/rules",
                        },
                        {
                            label: "Search Terms",
                            key: "/search-service/search-terms",
                        },
                        {
                            label: "Browser",
                            key: "/search-service/browser",
                        },
                        {
                            label: "EAV Attribute",
                            key: "/search-service/eav-attribute",
                        },
                    ]
                },
            ].filter(Boolean),
        },
    ]
    const scopes: string[] = localStorage.getItem('scopes')?.split(',') ?? [];
    if (data.role === Role.SUDO) {
        items.unshift(...SudoMenu)
    }

    if (scopes.includes('*') && scopes.length === 1) {
        items.push(...PaymentMenu, ...FirmBankingMenu, ...ReportMenu, ...searchMenu);
    } else {
        const checkAndPush = (menuItem: MenuItem[], target: string) => {
            if (scopes.some((item) => item.includes(target))) {
                items.push(...menuItem);
            }
        };
        checkAndPush(PaymentMenu, `${projectId}:payments`);
        checkAndPush(FirmBankingMenu, `${projectId}:firm-banking`);
        checkAndPush(ReportMenu, `${projectId}:reports`);
        checkAndPush(searchMenu, `${projectId}:elasticsearch`);
    }
    // const componentsSwitch = (e) => {
    //     navigate(e.key + (projectId ? '?projectId=' + projectId : ''));
    // };
    return (
        <Menu
            theme="dark"
            selectedKeys={[location.pathname, '/' + location.pathname.split('/')[1]]}
            defaultSelectedKeys={["/dashboard"]}
            mode="inline"
        // items={items}
        // onClick={(e) => componentsSwitch(e)}
        >
            {renderMenuItems(items, projectId)}
        </Menu>
    );
};
const renderMenuItems = (menu, projectId) => {
    return menu.map((item) => {
        if (item.children) {
            if (item.type === 'group') {
                return (
                    <Menu.ItemGroup key={item.key} title={item.label} >
                        {renderMenuItems(item.children, projectId)}
                    </Menu.ItemGroup>
                );
            } else {
                return (
                    <Menu.SubMenu key={item.key} title={item.label} icon={item.icon} >
                        {renderMenuItems(item.children, projectId)}
                    </Menu.SubMenu>
                );
            }

        } else {
            return (
                <Menu.Item key={item.key} icon={item.icon}>
                    <Link to={item.key + (projectId ? '?projectId=' + projectId : '')}>{item.label}</Link>
                </Menu.Item>
            );
        }
    });
};
export default MenuSidebar;

