import React from "react";

import { Main } from "../components";
import {forgotPasswordContainer} from "../containers"
export const ForgotPasswordPage: React.FC = () => {
  return (
    <div>
      <Main container = {forgotPasswordContainer} title="Forgot Password"/>
    </div>
  );
};