import React, { useState } from 'react'
import { Input, Button, Form, Collapse, Switch, Space, Descriptions, Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ldcc/client/src/redux/reducers';
import { AuthCookies, EndpointUser, apiPrivate } from '@ldcc/client/src/utils';
import { profileUpdateTwoFactorMethod } from "../redux/actions";
import { IProfileUpdateUpdateTwoFactorMethod } from "../redux/interface";
import { InputOTP } from "antd-input-otp";
const { Panel } = Collapse;
const SettingMultiFactor: React.FC = () => {
    const { data, loading } = useSelector((state: RootState) => state.profile);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageTOTP, setImageTOTP] = useState('')
    const [verifiesCode, setVerifiesCode] = useState('')
    const dispatch = useDispatch();

    const handleCancel = () => {
        setIsModalOpen(false);
        setImageTOTP('')
        setVerifiesCode('')
    };
    const handleConfirmCode = async (values) => {
        try {
            const response = await apiPrivate.post(`${EndpointUser.user}/${AuthCookies.userId}/multi-factor-authentication/totp`, {
                token: values.token.join('')
            });
            setVerifiesCode(response.data.toString().replace(/,/g, '\n'))
            dispatch(profileUpdateTwoFactorMethod({
                id: data?.id,
                twoFactorMethod: 'TOTP'
            }))
        } finally {

        }
    }
    const onChange2FA = async (method: string, checked: boolean) => {
        const dataSetTwoFactorMethod: IProfileUpdateUpdateTwoFactorMethod = {
            id: data?.id,
            twoFactorMethod: checked ? method : "NONE"
        }
        switch (dataSetTwoFactorMethod.twoFactorMethod) {
            case 'EMAIL':
                dispatch(profileUpdateTwoFactorMethod(dataSetTwoFactorMethod))
                break;
            case 'TOTP':
                try {
                    const { data: { img } } = await apiPrivate.post(`${EndpointUser.user}/${AuthCookies.userId}/multi-factor-authentication/totp`);
                    setIsModalOpen(true);
                    setImageTOTP(img)

                } finally {
                }
                break;
            default:
                dispatch(profileUpdateTwoFactorMethod(dataSetTwoFactorMethod))
        }
    };
    const [form] = Form.useForm();
    return (
        <>
            <Collapse>
                <Panel header="Two-factor authentication (2FA)" key="1">
                    <Space direction="vertical">
                        <Space direction="vertical">
                            <Switch
                              checkedChildren="Email"
                              unCheckedChildren="Email"
                              checked={data?.twoFactorMethod === "EMAIL"}
                              loading={loading}
                              onChange={(checked) => onChange2FA("EMAIL", checked)}
                            />
                            <Descriptions>
                                <Descriptions.Item label="Configure via Email">
                                    Receive login verification emails
                                </Descriptions.Item>
                            </Descriptions>
                        </Space>
                        <Space direction="vertical">
                            <Switch
                              checkedChildren="TOTP"
                              unCheckedChildren="TOTP"
                              checked={data?.twoFactorMethod === "TOTP"}
                              loading={loading}
                              onChange={(checked) => onChange2FA("TOTP", checked)}
                            />
                            <Descriptions>
                                <Descriptions.Item label="Configure via TOTP">
                                    Use a time-based one-time password for login
                                </Descriptions.Item>
                            </Descriptions>
                        </Space>
                    </Space>
                </Panel>
            </Collapse>


            <Modal title="Enter one-time password" open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
                <section style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <p>Scan this QR code in your authenticator app (e.g. Google Authenticator) and enter the one-time password.</p>
                    <Image
                        width={300}
                        src={imageTOTP}
                    />
                    <Form
                        layout="vertical"
                        onFinish={handleConfirmCode}
                        form={form}
                    >
                        <Form.Item label="TOTP Code" name="token">
                            <InputOTP
                              value={null}
                              autoFocus={true}
                              inputType="numeric"
                              autoSubmit={form}
                              length={6} // Set the number of OTP inputs
                            />
                        </Form.Item>
                    </Form >
                    {
                        verifiesCode ? <Descriptions title="Verify Codes" bordered>
                            <Descriptions.Item span={3} >
                                Each code can only be used once
                            </Descriptions.Item>
                            <Descriptions.Item label="Codes" span={3}>
                                <>
                                <pre>
                                    {verifiesCode}
                                </pre>
                                </>
                            </Descriptions.Item>
                        </Descriptions> : <></>
                    }

                </section>

            </Modal>
        </>
    )
}

export default SettingMultiFactor;