import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    deleteAttributes,
    getAttributes,
    getCountAttributes,
    getAttributeDetail,
    editAttributeDetail, mappingAttribute, addAttribute
} from "./index.action";

const initialState = {
    loading: false,
    attributes: null,
    countAttributes: null,
    attribute:null,
    mapping:null,
    error: null
};

const AttributesSlice = createSlice({
    name: "attributes",
    initialState,
    reducers: {},
    extraReducers(builder) {
        //get attributes
        builder.addCase(getAttributes.pending, (state, action) => {
            state.error = null;
            // @ts-ignore
            state.attributes = null;
            state.loading = true;
        });
        builder.addCase(getAttributes.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.attributes = action.payload;
        });
        builder.addCase(getAttributes.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.attributes = null;
        });

        //count Attribute
        builder.addCase(getCountAttributes.pending, (state, action) => {
            state.error = null;
            // @ts-ignore
            state.countAttributes = null;
            state.loading = true;
        });
        builder.addCase(getCountAttributes.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.countAttributes = action.payload;
        });
        builder.addCase(getCountAttributes.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            // @ts-ignore
            state.countAttributes = null;
        });

        //delete attributes
        builder.addCase(deleteAttributes.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteAttributes.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteAttributes.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
        });
        
        //get AttributeDetail
        builder.addCase(getAttributeDetail.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getAttributeDetail.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.attribute = action.payload;
        });
        builder.addCase(getAttributeDetail.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //edit AttributeDetail
        builder.addCase(editAttributeDetail.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(editAttributeDetail.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.attribute = action.payload;
        });
        builder.addCase(editAttributeDetail.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //mapping Attribute
        builder.addCase(mappingAttribute.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            state.mapping = null
        });
        builder.addCase(mappingAttribute.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.mapping = action.payload;
        });
        builder.addCase(mappingAttribute.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.mapping = null;
        });

        //add Attribute
        builder.addCase(addAttribute.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            state.attributes = null
        });
        builder.addCase(addAttribute.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.attributes = action.payload;
        });
        builder.addCase(addAttribute.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.attributes = null;
        });
    }
});

export default AttributesSlice.reducer;