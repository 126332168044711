import React from "react";

import { Main} from "../components";
import {resetPasswordContainer} from "../containers"
export const ResetPasswordPage: React.FC = () => {
  return (
    <div>
      <Main container = {resetPasswordContainer}/>
    </div>
  );
};