import React from "react";
import { Button, Form, Input } from 'antd';
import { notification } from 'antd';
import { api } from "@ldcc/client/src/utils";
import { Link } from "react-router-dom"
import { EndpointAuth } from "@ldcc/client/src/utils/constant";

const forgotPasswordContainer: React.FC = (props) => {
    const [loading, setLoading] = React.useState(false);
   
    const onFinish = async (values: any) => {
        const { email } = values;
        setLoading(true)
        try {
           await api.post(EndpointAuth.forgotPassword,{
            email
           });
           notification.success({
            message: "Change password",
            description: "Please check your email",
        });
        } catch(e) {
            notification.error({
                message: 'Oops!',
                description: e,
            });
        }
        finally {
            setLoading(false)
        }

    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!', type: "email" }]}

            >
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Send
                </Button>
                Or <Link to="/register">register now!</Link>
            </Form.Item>
        </Form>
    )
}
export default forgotPasswordContainer;