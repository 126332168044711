import React from 'react'
import { Form, Input } from "antd";
import { FormItemProps } from "antd/lib/form";

export const FormInputPassword: React.FC<FormItemProps & {placeholder?: string}> = ({placeholder='' ,...rest}) => {
    return (
        <Form.Item
            {...rest}
            rules={[
                {
                    required: true,
                    message: 'Please input your password!'
                },
                {
                    pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?.]{10,30}$/),
                    message: 'The password must contain at least ten characters, at least one number and both lower and uppercase letters and special characters'
                }
            ]}
        >
            <Input.Password placeholder={placeholder}/>
        </Form.Item>
    )
}