import { notification } from "antd";

export const notificationUpdated = () => notification.success({
    message: 'Success!',
    description: 'Updated',
});

export const notificationSuccess = () => notification.success({
    message: 'Success!',
    description: 'Success',
});