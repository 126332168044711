import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTree, getTreeDetail, syncProduct, getProducts, getBoostingProducts, addBoostingProducts, productSyncById } from "./index.action";

const initialState = {
    loading: false,
    error: null,
    tree: null,
    detail: null,
    products: null,
    boostingProducts: null
};

const CategoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getTree.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getTree.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.tree = action.payload
        });
        builder.addCase(getTree.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //get detail tree
        builder.addCase(getTreeDetail.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getTreeDetail.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.detail = action.payload
        });
        builder.addCase(getTreeDetail.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //sync product
        builder.addCase(syncProduct.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(syncProduct.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(syncProduct.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //get products
        builder.addCase(getProducts.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.products = action.payload
        });
        builder.addCase(getProducts.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //boosting products
        builder.addCase(getBoostingProducts.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getBoostingProducts.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.boostingProducts = action.payload
        });
        builder.addCase(getBoostingProducts.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //add boosting products
        builder.addCase(addBoostingProducts.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(addBoostingProducts.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(addBoostingProducts.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //products sync by id
        builder.addCase(productSyncById.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(productSyncById.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(productSyncById.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
    }
});

export default CategoriesSlice.reducer;