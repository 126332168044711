import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectTransaction, projectRefundTransaction, firmBankingTransaction } from "./index.action";
import { ITransactionResponse } from './index.interface';

const initialState = {
    loading: false,
    transaction: {} as ITransactionResponse,
    error: null
};

const ProjectTransactionSlice = createSlice({
    name: "project-transaction",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(projectTransaction.pending, (state, action) => {
            state.error = null;
            state.transaction = {} as ITransactionResponse;
            state.loading = true;
        });
        builder.addCase(projectTransaction.fulfilled, (state, action:PayloadAction<ITransactionResponse>) => {
            state.error = null;
            state.loading = false;
            state.transaction = action.payload;
        });
        builder.addCase(projectTransaction.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transaction = {} as ITransactionResponse;
        });
        // refund 
        builder.addCase(projectRefundTransaction.pending, (state, action) => {
            state.error = null;
            state.transaction = {} as ITransactionResponse;
            state.loading = true;
        });
        builder.addCase(projectRefundTransaction.fulfilled, (state, action:PayloadAction<ITransactionResponse>) => {
            state.error = null;
            state.loading = false;
            state.transaction = action.payload;
        });
        builder.addCase(projectRefundTransaction.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transaction = {} as ITransactionResponse;
        });
        // firm banking 
        builder.addCase(firmBankingTransaction.pending, (state, action) => {
            state.error = null;
            state.transaction = {} as ITransactionResponse;
            state.loading = true;
        });
        builder.addCase(firmBankingTransaction.fulfilled, (state, action:PayloadAction<ITransactionResponse>) => {
            state.error = null;
            state.loading = false;
            state.transaction = action.payload;
        });
        builder.addCase(firmBankingTransaction.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transaction = {} as ITransactionResponse;
        });
    }
});

export default ProjectTransactionSlice.reducer;