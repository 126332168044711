import { BaseServices, EndpointProjects } from "@ldcc/client/src/utils";
import { RequestAddMemberProject, RequestDeleteMemberProject, IProjectMemberShips, IProject, RequestEditProject, RequestGetMemberProject, RequestUpdateMembersProject, membershipRoles, RequestCreateAndUpdateGroupRole, RequestGetGroupRole } from "./project.interface";

class ProjectServices extends BaseServices {
    // membership
    async getMembersProject(projectId: string): Promise<Array<IProjectMemberShips>>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${projectId}/memberships`)).data;
    }

    async getMemberProject(request: RequestGetMemberProject ): Promise<IProjectMemberShips>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${request.projectId}/memberships/${request.id}`)).data;
    }

    async updateMemberProject(request: RequestUpdateMembersProject ): Promise<IProjectMemberShips>{
        const { projectId, id, ...rest } = request;
        return (await this.axiosPrivate.patch(`${this.endpoint}/${projectId}/memberships/${id}`,{
            role: rest.role,
            name: rest.name,
            membershipRoleId: rest.membershipRoleId
        })).data;
    }

    async addMemberProject(request: RequestAddMemberProject ): Promise<IProjectMemberShips>{
        const { projectId, ...rest } = request;
        return (await this.axiosPrivate.post(`${this.endpoint}/${projectId}/memberships`,{
            ...rest
        })).data;
    }
    async deleteMemberProject(request: RequestDeleteMemberProject): Promise<IProjectMemberShips>{
        return (await this.axiosPrivate.delete(`${this.endpoint}/${request.projectId}/memberships/${request.id}`)).data;
    }

    // project
    async getProject(projectId: string): Promise<IProject>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${projectId}`)).data;
    }
    async editProject(request: RequestEditProject) {
        return (await this.axiosPrivate.patch(`${this.endpoint}/${request.projectId}`, {
            id: request.id,
            name: request.name
        })).data;
    }

    // groups role in project 
    async getGroups(projectId: string): Promise<Array<membershipRoles>>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${projectId}/memberships/group/roles`)).data;
    } 

    async getGroup(request: RequestGetGroupRole): Promise<membershipRoles>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${request.projectId}/memberships/group/roles/${request.id}`)).data;
    }

    async createGroup(request: RequestCreateAndUpdateGroupRole): Promise<membershipRoles>{
        return (await this.axiosPrivate.post(`${this.endpoint}/${request.projectId}/memberships/group/roles`,{
            id: request.id,
            name: request.name,
            permissions: request.permissions
        })).data;
    } 

    async updateGroup(request: RequestCreateAndUpdateGroupRole): Promise<membershipRoles>{
        return (await this.axiosPrivate.patch(`${this.endpoint}/${request.projectId}/memberships/group/roles`,{
            id: request.id,
            name: request.name,
            permissions: request.permissions
        })).data;
    } 

    getProjectAction = this.createAsyncThunkFactory<string>('project/me', this.getProject.bind(this))
    
}

export default new ProjectServices(EndpointProjects.projects)