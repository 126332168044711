import React, {useEffect, useState} from "react";
import {notification} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@ldcc/client/src/redux/reducers";
import {ErrorDispatching} from "@ldcc/client/src/redux/interface";
import {getProductSettings} from "../../redux/slices/settings/index.action";
import TableSettingComponent from "./table-setting-component";

const ProductList = () => {
    const dispatch = useDispatch();
    const {products}: any = useSelector((state: RootState) => state.settings);
    const [dataSource, setDataSource] = useState(products)

    useEffect(() => {
        if(!products){
            getListSettings()
        }else {
            setDataSource(products)
        }
    }, [products]);
    const getListSettings = async () => {
        let status = await dispatch(getProductSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }

    return <TableSettingComponent tab="product" dataSource={dataSource}/>
}
export default ProductList;