import { notification } from 'antd';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { register } from "./index.action";
import { MessageNotifiCation } from '@ldcc/client/src/utils/constant';

const initialState = {
  loading: false,
  redirectUrl: ''
};

//slice
const RegisterSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state) => {
      state.loading = false;
      notification.success({
        message: MessageNotifiCation.REGISTER_SUCCESSFULLY,
        description: 'Please Check Your Email!',
      });
      state.redirectUrl = '/login'
    });
    builder.addCase(register.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      notification.error({
        message: MessageNotifiCation.ERROR,
        description: action.payload,
      });
      state.redirectUrl = ''
    })
  }
});

export default RegisterSlice.reducer;
