import { notification } from 'antd';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectTransactions, projectTransactionsWithPagination, projectRefundTransactionsWithPagination, projectFirmBankingTransactionsWithPagination } from "./index.action";
import { ITransactionResponse } from '../project-transaction/index.interface';

let transactionsInnit: Array<ITransactionResponse>
const initialState = {
    loading: false,
    transactions: transactionsInnit,
    error: null,
    total: 0,
    transactionsExport: transactionsInnit
};

const ProjectTransactionsSlice = createSlice({
    name: "project-transactions",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(projectTransactions.pending, (state, action) => {
            state.transactions = []
            state.error = null;
            state.loading = true;
        });
        builder.addCase(projectTransactions.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.transactions = action.payload;
        });
        builder.addCase(projectTransactions.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transactionsExport = null;
            state.transactions = [];
        });

        //get transactions with pagination
        builder.addCase(projectTransactionsWithPagination.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(projectTransactionsWithPagination.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.transactions = action.payload.data;
            state.transactionsExport = action.payload.dataExport;
            state.total = action.payload.total
        });
        builder.addCase(projectTransactionsWithPagination.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transactions = null;
            
            state.transactionsExport = null;
        });

         //get transactions with pagination
        builder.addCase(projectRefundTransactionsWithPagination.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(projectRefundTransactionsWithPagination.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.transactions = action.payload.data;
            state.transactionsExport = action.payload.dataExport;
            state.total = action.payload.total
        });
        builder.addCase(projectRefundTransactionsWithPagination.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transactions = null;
            
            state.transactionsExport = null;
        });

        //get transactions firm banking with pagination
        builder.addCase(projectFirmBankingTransactionsWithPagination.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(projectFirmBankingTransactionsWithPagination.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.transactions = action.payload.data;
            state.transactionsExport = action.payload.dataExport;
            state.total = action.payload.total
        });
        builder.addCase(projectFirmBankingTransactionsWithPagination.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transactions = null;
            
            state.transactionsExport = null;
        });
    }
});

export default ProjectTransactionsSlice.reducer;