import React from "react";
import { notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "utils";
import { EndpointAuth } from "utils/constant";

const Verify: React.FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const verifyEmail = async () => {
        if (token) {
            try {
                await api.post(EndpointAuth.emailHandleVerification, {
                    token,
                })
                notification.success({
                    message: "Verify Email",
                    description: "success",
                });
                navigate("/login");
            } catch (e) {
                notification.error({
                    message: "Oops!",
                    description: e,
                });
                navigate("/verify");
            }
        }
    }
    React.useEffect(() => {
        verifyEmail()
    }, []);
    return <div>verify Email</div>;
};

export default Verify;
