import {apiPrivate} from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataType } from "./index.interface";
const PATH = '/es';

export const getProductList = createAsyncThunk(
    'api/get-list-product',
    async (data:any) => {
        const res = await apiPrivate.get(`${PATH}/products`, {data:data});
        return res.data;
    }
)

export const getCountProducts = createAsyncThunk(
    'api/get-count-product',
    async () => {
        const res = await apiPrivate.get(`${PATH}/products/count`);
        return res.data;
    }
)

export const editProduct = createAsyncThunk(
    'api/edit-product',
    async (data: any) => {
        const res = await apiPrivate.put(`${PATH}/products/${data.objectID}`, data);
        return res.data;
    }
)

