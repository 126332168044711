import { IProjectsMemberShip } from './project-membership.interface';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProject } from '@ldcc/project/src/redux/interface';
import ProjectsMembershipServices from './project-membership.service'
import { Role } from '@ldcc/client/src/utils';
const projects: Array<IProjectsMemberShip> = []
const projectOwner: IProject = {
    id: '',
    name: '',
    attributes: '',
    autoJoinDomain: false,
    forceTwoFactor: false,
    ipRestrictions: '',
    onlyAllowDomain: false,
    profilePictureUrl: '',
    createdAt: '',
    updatedAt: ''
}
const initialState = {
    loading: false,
    projectsMembership: projects,
    projectOwner: projectOwner
}

const ProjectsSlice = createSlice({
    name: "me/projects/membership",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(ProjectsMembershipServices.getProjectsMembershipAction.pending, (state, action) => {
            state.loading = true;
            state.projectsMembership = null;
            state.projectOwner = null;
        });
        builder.addCase(ProjectsMembershipServices.getProjectsMembershipAction.fulfilled, (state, action: PayloadAction<Array<IProjectsMemberShip>>) => {
            state.loading = false;
            state.projectsMembership = action.payload;
            state.projectOwner = action.payload.find(item => item.role === Role.OWNER)?.project || action.payload[0]?.project    
        });
        builder.addCase(ProjectsMembershipServices.getProjectsMembershipAction.rejected, (state, action) => {
            state.loading = false;
            state.projectsMembership = null;
            state.projectOwner = null;
        });

        //SUDO
        builder.addCase(ProjectsMembershipServices.getProjectsSudoAction.pending, (state, action) => {
            state.loading = true;
            state.projectsMembership = null;
            state.projectOwner = null;
        });
        builder.addCase(ProjectsMembershipServices.getProjectsSudoAction.fulfilled, (state, action: PayloadAction<Array<IProjectsMemberShip>>) => {
            state.loading = false;
            state.projectsMembership = action.payload;
            state.projectOwner = action.payload[0].project
        });
        builder.addCase(ProjectsMembershipServices.getProjectsSudoAction.rejected, (state, action) => {
            state.loading = false;
            state.projectsMembership = null;
            state.projectOwner = null;
        });
    }
});

export default ProjectsSlice.reducer;