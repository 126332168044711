import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiPrivate} from "@ldcc/client/src/utils";
import {IAttributes} from "./index.interface";

const PATH = '/es';
export const getAttributes = createAsyncThunk(
    'api/get-attributes',
    async () => {
        const respone = await apiPrivate.get(`${PATH}/attributes`);
        return respone.data;
    }
);

export const getCountAttributes = createAsyncThunk(
    'api/get-count-attributes',
    async () => {
        const respone = await apiPrivate.post(`${PATH}/attributes/count`);
        return respone.data;
    }
);

export const deleteAttributes = createAsyncThunk(
    'api/delete-attributes',
    async (id: string) => {
        const respone = await apiPrivate.delete(`${PATH}/attributes/${id}`);
        return respone.data;
    }
);

export const getAttributeDetail = createAsyncThunk(
    'api/get-attributes-detail',
    async (id: string) => {
        const respone = await apiPrivate.get(`${PATH}/attributes/${id}`);
        return respone.data;
    }
);

export const editAttributeDetail = createAsyncThunk(
    'api/edit-attributes-detail',
    async (data: IAttributes) => {
        const respone = await apiPrivate.put(`${PATH}/attributes/${data.id}`, data);
        return respone.data;
    }
);

export const addAttribute = createAsyncThunk(
    'api/add-attributes',
    async (data: IAttributes) => {
        const respone = await apiPrivate.post(`${PATH}/attributes`, data);
        return respone.data;
    }
);

export const mappingAttribute = createAsyncThunk(
    'api/mapping-attribute',
    async () => {
        const respone = await apiPrivate.post(`${PATH}/attributes/mapping`, {type:"product"});
        return respone.data;
    }
);