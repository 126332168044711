import { Layout } from "antd";
import classes from "./style.module.less";
import React from "react";
import MenuSidebar from "./Menu";
import { Link } from "react-router-dom";
const { Sider } = Layout;

interface Props {
    collapsed: boolean
}
export const Sidebar: React.FC<Props> = ({collapsed}) => {

    return (
        <Sider
            collapsible
            trigger={null}
            width={300}
            theme="light"
            collapsed={collapsed}
            className={`${classes.sidebar} ${collapsed ? 'collapsed-menu' : ''}`}

        >
            <Link to={`/`}>
            <div
                        className={`${!collapsed} ${
                            classes.logo
                        } ${!collapsed && classes.collapsed}`}
                    >
                        <img
                            src={
                                !collapsed
                                    ? "/images/logo.svg"
                                    : "/images/simple-logo.svg"
                            }
                            alt="Logo"
                            width="200"
                            height="50"
                        />
                    </div>
            </Link>
            <MenuSidebar />
        </Sider>
    );
};
