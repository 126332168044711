import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProductList, editProduct, getCountProducts} from "../products/index.action";

const initialState = {
    loading: false,
    error: null,
    productList: [],
    product : null,
    count: null
};

const ProductsSlice = createSlice({
    name: "productList",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getProductList.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getProductList.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.productList = action.payload
        });
        builder.addCase(getProductList.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //getCount
        builder.addCase(getCountProducts.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getCountProducts.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.count = action.payload
        });
        builder.addCase(getCountProducts.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //
        //edit
        builder.addCase(editProduct.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(editProduct.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.product = action.payload;
        });
        builder.addCase(editProduct.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
    }
})

export default ProductsSlice.reducer