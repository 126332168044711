import { Drawer as DrawerBase, DrawerProps } from "antd"

export const Drawer: React.FC<DrawerProps> = ({children ,...rest}) => {
    return (
        <DrawerBase
          width={'50%'}
          destroyOnClose={true}
          maskClosable={false}
          bodyStyle={{ paddingBottom: 80 }}
          forceRender
          {
            ...rest
          }
        >
            {children}
        </DrawerBase>
    )
}