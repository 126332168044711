import {apiPrivate} from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
const PATH = '/es';
export const getTree = createAsyncThunk(
    'api/get-tree',
    async () => {
        const res = await apiPrivate.get(`${PATH}/categories/tree`);
        return res.data;
    }
);

export const getTreeDetail = createAsyncThunk(
    'api/get-tree-detail',
    async (id:number|string) => {
        const res = await apiPrivate.get(`${PATH}/categories/${id}`);
        return res.data;
    }
);

export const syncProduct = createAsyncThunk(
    'api/sync-product',
    async (data:any) => {
        const res = await apiPrivate.post(`${PATH}/categories/sync`, data);
        return res.data;
    }
);

export const getProducts = createAsyncThunk(
    'api/get-products',
    async (data:any) => {
        const res = await apiPrivate.post(`${PATH}/categories/${data.id}/products`, {params: data.params});
        return res.data;
    }
);

export const getBoostingProducts = createAsyncThunk(
    'api/get-boosting-products',
    async (id:any) => {
        const res = await apiPrivate.post(`${PATH}/categories/${id}/boosting/products`, {});
        return res.data;
    }
);

export const addBoostingProducts = createAsyncThunk(
    'api/add-boosting-products',
    async (data:any) => {
        const res = await apiPrivate.post(`${PATH}/categories/${data.id}/boosting/add`, data.payload);
        return res.data;
    }
);

export const productSyncById = createAsyncThunk(
    'api/product-sync-by-id',
    async (data:any) => {
        const res = await apiPrivate.put(`${PATH}/categories/${data.id}/products/sync`, {});
        return res.data;
    }
);