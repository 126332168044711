import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHistory, getCount } from "./index.action";

const initialState = {
    loading: false,
    error: null,
    history: null,
    count: null,
};

const CategoriesSlice = createSlice({
    name: "history",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getHistory.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getHistory.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.history = action.payload
        });
        builder.addCase(getHistory.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });

        //get count
        builder.addCase(getCount.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getCount.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.count = action.payload
        });
        builder.addCase(getCount.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
    }
});

export default CategoriesSlice.reducer;