import jwtDecode from "jwt-decode";
import { api, AuthCookies, IUserInformation, RemoveSessionLogin } from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ILogin, RequestLoginTotp } from "./index.interface";
import { EndpointAuth } from "@ldcc/client/src/utils/constant";

const decode = (data): IUserInformation => {
  const decode: IUserInformation = jwtDecode(data.accessToken);
  AuthCookies.value = {
    id: decode.id,
    refreshToken: data.refreshToken
  };
  localStorage.setItem('accessToken', data.accessToken.toString())
  localStorage.setItem('scopes', decode.scopes.toString())
  return decode;
}

// action
export const login = createAsyncThunk(
  'auth/login',
  async (login: ILogin, thunkApi) => {
    try {
      const { data } = await api.post(EndpointAuth.login, login);
      if (data.totpToken) {
        return data
      }
      return decode(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loginWithMailMultiFactor = createAsyncThunk(
  'auth/login-with-mail',
  async (token: string, thunkApi) => {
    try {
      const { data } = await api.post(EndpointAuth.loginWithMailToken, {
        token
      });
      return decode(data);
    }catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const LoginWithTOTPMultiFactor = createAsyncThunk(
  'auth/login-with-totp',
  async (request: RequestLoginTotp, thunkApi) => {
    try {
      const { data } = await api.post(EndpointAuth.loginWithTOTPCode, {
        ...request
      });
      return decode(data);
    }catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const logout = createAsyncThunk(
  'auth/logout',
  async (token: string, thunkApi) => {
    try {
      await api.post(EndpointAuth.logout, {
        token: token,
      });
      RemoveSessionLogin()
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  });