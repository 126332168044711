import { EndpointProjects, apiPrivate } from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSearchTermList = createAsyncThunk(
  "api/get-search-term-list",
  async (params: {
    projectId: string;
    page_size: number;
    page_number: number;
    query: string;
  }) => {
    const {
      projectId,
      page_number: pageNumber,
      page_size: pageSize,
      query,
    } = params;
    const res = await apiPrivate.get(
      `${EndpointProjects.projects}/${projectId}/es/default/admin/search-terms?page_size=${pageSize}&page_number=${pageNumber}&query=${query}`
    );
    return res.data;
  }
);
export const getCountSearchTerm = createAsyncThunk(
  "api/get-count-search-term-list",
  async () => {
    const res = await apiPrivate.post(
      `v1/p/:projectId/es/:storeCode/admin/search-terms`
    );
    return res.data;
  }
);
