import Storage from './cookie';

export interface IUserInformation {
    id: string;
    role: string;
    scopes: Array<string>;
}
class AuthCookies extends Storage {
    get loggedIn() {
        return !!this.value && !!this.value.id;
    }

    get scopes() {
        return this.value && this.value.scopes;
    }

    get userId() {
        return this.value && this.value.id;
    }

    get role() {
        return this.value && this.value.role;
    }
    get refreshToken() {
        return this.value && this.value.refreshToken;
    }
}

export default new AuthCookies('AUTH');
