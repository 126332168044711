import React from "react";
import { Card as AntdCard } from "antd";
import { CardProps } from "antd/lib/card";
import style from "./style.module.less"

export const Card: React.FC<CardProps> = ({ title, children, ...rest }) => {
  return (
    <AntdCard 
      title={title} 
      className={style.card}
      {...rest}
    >
      {children}
    </AntdCard>
  );
};
