import {apiPrivate} from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
const PATH = '/es';

export const getSynonymsList = createAsyncThunk(
    'api/get-synonyms-list',
    async () => {
        const res = await apiPrivate.post(`${PATH}/synonyms/search`);
        return res.data;
    }
)
export const getCountSynonyms = createAsyncThunk(
    'api/get-count-synonyms',
    async () => {
        const res = await apiPrivate.post(`${PATH}/synonyms/count`);
        return res.data;
    }
)
export const deleteSynonym = createAsyncThunk(
    'api/delete-synoym',
    async (id:any) => {
        const res = await apiPrivate.delete(`${PATH}/synonyms/${id}`);
        return res.data;
    }
)
export const getSynonym = createAsyncThunk(
    'api/get-synonym',
    async (id:any) => {
        const res = await apiPrivate.get(`${PATH}/synonyms/${id}`);
        return res.data;
    }
)
export const addOrUpdateSynonym = createAsyncThunk(
    'api/add-update-synonym',
    async (data: any) => {
        const res = await apiPrivate.put(`${PATH}/synonyms`, data);
        return res.data;
    }
)
export const getFileExprt = createAsyncThunk(
    'api/export-file',
    async () => {
        const res = await apiPrivate.post(`${PATH}/synonyms/export`);
        return res.data;
    }
)
export const deleteAll = createAsyncThunk(
    'api/clear-synonyms',
    async () => {
        const res = await apiPrivate.post(`${PATH}/synonyms/clear`);
        return res.data;
    }
)
export const applySynonyms = createAsyncThunk(
    'api/apply-synonyms',
    async () => {
        const res = await apiPrivate.post(`${PATH}/synonyms/apply`);
        return res.data;
    }
)