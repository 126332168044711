import React from "react";
import classes from "./index.module.less";
interface children {
	container: React.FC
	title?: string
}
export const Main: React.FC<children> = (props) => {
	const { container: Container, title } = props;
  return (
    <div className={classes.wrapper}>
			<div className={classes.left}>
				<div className={classes.leftOverlay} />
				<div className={classes.leftContent}>
					<div className="ml-4 flex-1">
						<h1 className="pt-0 text-white">LOTTE DATA COMMUNICATION</h1>
						<p className="">Lotte API Transit Gateway</p>
					</div>
				</div>
			</div>
			<div className={classes.right}>
				<div className={classes.box}>
						<h2>{title}</h2>
						<Container />
				</div>
			</div>
		</div>
  );
};
