import React from "react";
import style from './style.module.less'
interface Props {
    loading: boolean;
}
export const Loading: React.FC<Props> = ({ loading }) => {
    if (!loading) {
        return null;
    }

    return (
        <span className={style['custom-loader']}></span>
    );
};
