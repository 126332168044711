import { CaretDownOutlined } from "@ant-design/icons";
import { Select as SelectBase, SelectProps } from "antd";
import React from "react";
import style from "./style.module.less";

export interface IDataSelect {
    value: string | number;
    data: string | number;
}
interface ISelect {
    data: Array<IDataSelect>;
    label?: string;
}

export const Select: React.FC<ISelect & SelectProps> = ({
    data,
    label,
    ...rest
}) => {
    const { Option } = SelectBase;
    return (
        <div className={style.select}>
            {label && <label className={style.label}>{label}</label>}
            <SelectBase
                suffixIcon={<CaretDownOutlined />}
                bordered={false}
                {...rest}
            >
                {data && data.map((value) => {
                    return <Option key={value.value} value={value.value}>{value.data}</Option>;
                })}
            </SelectBase>
        </div>
    );
};
