import {apiPrivate} from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
const PATH = '/es';
export const getHistory = createAsyncThunk(
    'api/get-history',
    async (data:any) => {
        console.log(data)
        const res = await apiPrivate.post(`${PATH}/history/search`, data);
        return res.data;
    }
);
export const getCount = createAsyncThunk(
    'api/get-count',
    async (data:any) => {
        const res = await apiPrivate.post(`${PATH}/history/count`, data);
        return res.data;
    }
);
