import { apiPrivate, queryListString } from '@ldcc/client/src/utils';
import { EndpointProjects } from '@ldcc/client/src/utils/constant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEntities, RequestEntity } from './index.interface';
export const getStoreByIdProject = createAsyncThunk(
    'project/es/store',
    async (projectId: string, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${projectId}/es/store`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const getEntities = createAsyncThunk(
    'project/es/entities',
    async (data: RequestEntities, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${data.projectId}/es/${data.storeCode}/admin/browser/entities`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);


export const getInfoEntity = createAsyncThunk(
    'project/es/entity',
    async (request: RequestEntity, thunkApi) => {
        try {
            const query = `${EndpointProjects.projects}/${request.projectId}/es/${request.storeCode}/admin/browser/entities/${request.entity}?pageSize=${request.pageSize}&pageNumber=${request.pageNumber}&query=${request.where?.query??''}`;
            const response = await apiPrivate.get(query);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)