import React from "react";
import { MenuOutlined, ShoppingCartOutlined,KeyOutlined, ShoppingOutlined, OrderedListOutlined} from "@ant-design/icons";
import {Card, Tabs} from 'antd';
import SuggestionList from "../../components/settings/suggestion-list";
import AllList from "../../components/settings/all-list";
import {useNavigate, useParams} from "react-router-dom";
import IndexingList from "../../components/settings/indexing-list";
import ProductList from "../../components/settings/product-list";
import SalesList from "../../components/settings/sales-list";
import MagentoList from "../../components/settings/magento-list";
import SettingEdit from "../../components/settings/settings-edit";

const SearchSettingsService: React.FC = () => {
    const navigate = useNavigate();
    let {keySetting}: any = useParams();
    let windowUrl = window.location.pathname
    let settingsUrl = "/search-service/settings"
    let suggestionUrl = settingsUrl  + "/suggestion"
    let indexingUrl = settingsUrl + "/indexing"
    let productUrl = settingsUrl + "/product"
    let salesUrl = settingsUrl + "/sales"
    let magentoUrl = settingsUrl + "/magento"
    let allSettingUrl = settingsUrl + "/all"
    let settingEditUrl = settingsUrl + "/" +keySetting+"/edit"

    const onTabClick = async (key: string) => {
        switch (key) {
            case "1":
                navigate(settingsUrl);
                return;
            case "2":
                navigate(indexingUrl)
                return;
            case "3":
                navigate(productUrl)
                return;
            case "4":
                navigate(salesUrl)
                return;
            case "5":
                navigate(magentoUrl)
                return;
            case "6":
                navigate(allSettingUrl)
                return;
            default:
                navigate(suggestionUrl)
                return;
        }
    }

    const getDefaultCase: any = () => {
        switch (windowUrl) {
            case settingsUrl:
                return "1";
            case indexingUrl:
                return "2";
            case productUrl:
                return "3";
            case salesUrl:
                return "4";
            case magentoUrl:
                return "5";
            case allSettingUrl:
                return "6";
            default:
                return "1"

        }
    }

    return (
        <Card bordered={false}>
            <Tabs type="card" defaultActiveKey={getDefaultCase} onTabClick={(k) => {onTabClick(k)}}
                items={[
                    {
                        label:<span> <MenuOutlined />Suggestion</span>,
                        key: '1',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <SuggestionList/>
                    },
                    {
                        label: <span><OrderedListOutlined />Indexing</span>,
                        key: '2',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <IndexingList/>
                    },
                    {
                        label: <span><ShoppingOutlined /> Product Data</span>,
                        key: '3',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <ProductList/>
                    },
                    {
                        label: <span><ShoppingCartOutlined />Sales Data</span>,
                        key: '4',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <SalesList/>
                    },
                    {
                        label: <span><ShoppingCartOutlined />Magento Integration</span>,
                        key: '5',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <MagentoList/>
                    },
                    {
                        label: <span> <KeyOutlined />All Settings</span>,
                        key: '6',
                        children: windowUrl === settingEditUrl ? <SettingEdit/> : <AllList/>
                    },
                ]}
            />
        </Card>
    );
};

export default SearchSettingsService;
