import moment from "moment";
import { argsList } from "redux/interface";
import { AuthCookies, Role } from "utils";

export const dynamicSortObjectArray = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const FormatDateTime = (property) => {
    return moment(property).format('YYYY-MM-DD')
}

export const queryListString = (query: string, property: argsList) => {
    let where = 'where=';
    let orderBy = '';
    let pagination = '';
    let search = '';
    let filters = '';

    for (const prop in property) {
        switch (prop) {
            case 'from':
                where += `fromDate:${property.from.format('YYYY-MM-DD')},`;
                break;
            case 'to':
                where += `toDate:${property.to.format('YYYY-MM-DD')}`;
                break;
            case 'filters':
                for (let [key, value] of Object.entries(property.filters)) {

                    if (!!value) {
                        filters = filters ? '-' : ',filters:';
                        filters += `${Array.isArray(value) && value.reduce((prevVal, currVal) => (prevVal ? prevVal + '-' : prevVal) + `${key}:${currVal}`, '')}`
                    }
                    where += filters
                    // if (!!value) {
                    //     where += `,filters:${Array.isArray(value) && value.reduce((prevVal, currVal) => (prevVal ? prevVal+'-' : prevVal) + `${key}:${currVal}`, '')}`
                    // }
                }

                break;
            case 'sorter':
                orderBy += !!property.sorter.order ? `orderBy=${property.sorter.orderBy}:${property.sorter.order}` : '';
                break;
            case 'pagination':
                pagination = property.pagination?.take ? `?take=${property.pagination.take}&skip=${property.pagination?.skip ?? 0}` : '';
                break;
            case 'search':
                for (let [key, value] of Object.entries(property.search)) {
                    if (!!value) {
                        search += `${Array.isArray(value) && value.reduce((prevVal, currVal) => (prevVal ? prevVal + ',' : prevVal) + `${key}:${currVal}`, ' ')}`
                    }
                }
                break;
            default:
                break;
        }
    }
    return query + (pagination ? pagination + '&' : '?') + (orderBy ? orderBy + '&' : '') + (search ? where + search : where)
}

export const getSortOrderOfAntd = {
    'descend': 'desc',
    'ascend': 'asc',
    undefined: 'desc'
}
export const compareTwoArraysOrObject = (argFirt, argSecond) => {
    return JSON.stringify(argFirt) === JSON.stringify(argSecond);
};

export const TransFormFromStringToArrayByCommaSymbol = (text: string) => {
    return Array.from(text.toString().replace(/(?:\r\n|\r|\n)/g, '').trim().split(',')).map(item => item.trim());
}

/**
@description This function takes in a string representation of a date and transforms it into a localized string format.
@param time The string representation of a date that needs to be transformed.
@returns A string that represents the input date in a localized format. 
*/
export const TransFormFromTextToLocalString = (time: string): string => {
    const date = moment(time).format('YYYY-MM-DD HH:mm:ss');
    return date;
}

/**
@description This function takes in a unix representation of a date and transforms it into a localized string format.
@param time The digital representation of a date that needs to be transformed.
@returns A string that represents the input date in a localized format. 
*/
export const TransFormFromUnixToLocalString = (time: number): string => {
    const date = moment.unix(time).format('YYYY-MM-DD HH:mm:ss');
    return date;
}


export const RemoveSessionLogin = () => {
    AuthCookies.destroy();
    localStorage.removeItem('accessToken')
    localStorage.removeItem('scopes')
}

/**
 * Checks if a user has the required access level.
 * @param userLevel The access level of the user.
 * @param requiredLevel The minimum required access level.
 * @returns True if the user has access, false otherwise.
 */

export const CheckAccessLevel = (userLevel: Role, requiredLevel: Role): boolean => {
    const accessLevels = Object.values(Role);
    const userIndex = accessLevels.indexOf(userLevel);
    const requireIndex = accessLevels.indexOf(requiredLevel)
    if (userIndex === -1 || requireIndex === -1) {
        return false
    }

    if (userIndex >= requireIndex) {
        return false;
    }
    return true;
}


export const HasPermission = (permission: string): boolean => {
    const scopes: string[] = localStorage.getItem('scopes')?.split(',') ?? [];
    return scopes.includes(permission) || scopes.includes('*');
}

export const GetPermissionByProjectId = (projectId: string, permission: string): string => {
    return permission.replace('projectId', projectId)
}