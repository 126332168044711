import React, {useEffect, useState} from "react";
import {notification} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@ldcc/client/src/redux/reducers";
import {ErrorDispatching} from "@ldcc/client/src/redux/interface";
import {getIndexSettings} from "../../redux/slices/settings/index.action";
import TableSettingComponent from "./table-setting-component";

const IndexingList = () => {
    const dispatch = useDispatch();
    const {indexing}: any = useSelector((state: RootState) => state.settings);
    const [dataSource, setDataSource] = useState(indexing)

    useEffect(() => {
        if (!indexing) {
            getListSettings()
        } else {
            setDataSource(indexing)
        }
    }, [indexing]);
    const getListSettings = async () => {
        let status = await dispatch(getIndexSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }

    return <TableSettingComponent tab="indexing" dataSource={dataSource}/>
}
export default IndexingList;