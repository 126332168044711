import { Card } from "@ldcc/client/src/components";
import React from "react";
import { userProfileContainer as UserProfileContainer, SettingMultiFactor, ChangePassword } from "../containers"
import { Collapse, Row, Col } from 'antd';
const { Panel } = Collapse;
const UserProfilePage: React.FC = () => {
  return (
    <Card>
      <UserProfileContainer />
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Collapse>
            <Panel header="ChangePassword" key="1">
              <ChangePassword />
            </Panel>
          </Collapse>
        </Col>
        <Col className="gutter-row" span={12}>
          <SettingMultiFactor />
        </Col>
      </Row>
    </Card>
  )
};
export default UserProfilePage
