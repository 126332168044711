import React from "react";
import { Button, Form, Input } from 'antd';
import { notification } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ldcc/client/src/redux/reducers";
import { login } from "../redux/actions";
import { Role } from "@ldcc/client/src/utils";

const loginContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, loading } = useSelector((state: RootState) => state.auth);
    const onFinish = async (values: any) => {
        const { email, password } = values;
        const loginData = {
            email, password
        };
        dispatch(login(loginData))
    };
    React.useEffect(() => {
        if (user?.id) {
            navigate('/profile')
        }
        if (user?.type === 'EMAIL') {
            notification.success({
                message: 'notification!',
                description: "please check email!",
            });
        }
        if (user?.type === 'TOTP') {
            navigate(`/login/totp?token=${user?.totpToken}`)
        }

    }, [navigate, user?.id, user?.role, user?.totpToken, user?.type]);
    return (
        <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!', type: "email" }]}

            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!'
                    }
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Login
                </Button>
                Or <Link to="/register">register now!</Link> <br></br>
                If you're unable to log in, you can <Link to="/forgot">reset your password.</Link> <br></br>
                If you haven't received the link to verify your email, you can <Link to="/verify">resend email verification </Link>after a few minutes.
            </Form.Item>
        </Form>
    );

}

export default loginContainer;