import { notification } from 'antd';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectOrderTransactions } from "./index.action";

const initialState = {
    loading: false,
    transactions: [],
    error: null
};

const ProjectOrderTransactionsSlice = createSlice({
    name: "project-order-transactions",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(projectOrderTransactions.pending, (state, action) => {
            state.error = null;
            state.transactions = [];
            state.loading = true;
        });
        builder.addCase(projectOrderTransactions.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.transactions = action.payload;
        });
        builder.addCase(projectOrderTransactions.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.transactions = [];
        });
    }
});

export default ProjectOrderTransactionsSlice.reducer;