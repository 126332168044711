import { apiPrivate } from "@ldcc/client/src/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

export default class BaseServices {
    protected axiosPrivate = apiPrivate;
    protected endpoint = ''
    constructor(endpoint: string) {
        this.endpoint = endpoint
    }

    protected createAsyncThunkFactory<T>(typePrefix: string, apiCall: (request?: T | undefined) => any) {
        return createAsyncThunk(
            `${typePrefix}`,
            async (request: T | undefined, thunkApi) => {
                try {
                    const response = await apiCall(request);
                    return response;
                } catch (error) {
                    return thunkApi.rejectWithValue(error)
                }
            }
        );
    }
}
