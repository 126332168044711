import React, {useEffect, useState} from "react";
import {Button, Card, Col, Divider, Form, Input, notification, Row, Space, Tabs, Select} from "antd";
import {ErrorDispatching} from "@ldcc/client/src/redux/interface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@ldcc/client/src/redux/reducers";
import {useNavigate, useParams} from 'react-router-dom';
import {getCountSettings, getSetting, getSuggestionSettings} from "../../redux/actions";
import {editSetting} from "../../redux/slices/settings/index.action";
import {EditOutlined} from "@ant-design/icons";

const SettingEdit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {setting}: any = useSelector((state: RootState) => state.settings);
    const [newSetting, setNewSetting]: any = useState(null);
    // @ts-ignore
    let {keySetting}: any = useParams();

    useEffect(() => {
        if (!setting || (keySetting && keySetting !== setting.key)) {
            getSettingDetail()
        }
        setNewSetting(setting)
    }, [setting]);
    const getSettingDetail = async () => {
        const status = await dispatch(getSetting(keySetting)) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const getListSuggestion = async () => {
        const status = await dispatch(getSuggestionSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }

    const getCountSetting = async () => {
        const status = await dispatch(getCountSettings()) as any as ErrorDispatching;
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
            });
        }
    }
    const onSave = async () => {
        const status = await dispatch(editSetting(newSetting)) as any as ErrorDispatching
        if (status.error) {
            notification.error({
                message: 'Opp!',
                description: status.error.message,
                duration: 3
            });
        } else {
            notification.success({
                message: 'Save Setting Success',
                description: `${newSetting.key} has been saved successfully`,
                duration: 3
            });
            getListSuggestion();
            getCountSetting();
            // @ts-ignore
            window.location = "/search-service/settings/suggestion";
            // navigate("/search-service/settings/suggestion")
        }
    }
    const onCancel = () => {
        // @ts-ignore
        window.location = "/search-service/settings/suggestion";
        // navigate("/search-service/settings/suggestion")
    }
    const setNewValueSetting = (value: any, fieldName: string) => {
        let newData = Object.assign({}, newSetting)
        newData[fieldName] = value
        setNewSetting(newData)
    }

    const typeOption = [{value: 'boolean', label: 'boolean'},
        {value: 'integer', label: 'integer'},
        {value: 'decimal', label: 'decimal'},
        {value: 'string', label: 'string'},
        {value: 'array', label: 'array'}]

    return (
        <div>
            <Card bordered={true}>
                <h1 style={{fontSize: "1.25rem"}}>
                <span>{newSetting ? newSetting.key : null}
                    &nbsp;<small style={{fontWeight: "normal"}}>{newSetting ? newSetting.type : null}</small>
                </span>
                </h1>
                <Tabs type="card" defaultActiveKey={"6"}
                      items={[
                          {
                              label: <span><EditOutlined />Edit</span>,
                              key: 'edit-setting',
                              children: <Row gutter={20}>
                                  <Col span={24}>
                                      <div style={{border: '1px solid #cfd8dc', padding: "1.25rem"}}>
                                          <Form layout="vertical">
                                              <Form.Item label="Key">
                                                  <Input disabled value={newSetting ? newSetting.key : null}/>
                                              </Form.Item>
                                              <Form.Item label="Type">
                                                  <Select options={typeOption} disabled
                                                          value={newSetting ? newSetting.type : null}>
                                                  </Select>
                                              </Form.Item>
                                              <Form.Item label="Value">
                                                  <Input name="value" contentEditable={true}
                                                         onChange={(v) => setNewValueSetting(v.target.value, "value")}
                                                         value={newSetting ? newSetting.value : null}/>
                                              </Form.Item>
                                          </Form>
                                          <Divider/>
                                          <Space>
                                              <Button onClick={onSave} type="primary">Save</Button>
                                              <Button onClick={onCancel}>Cancel</Button>
                                          </Space>
                                      </div>
                                  </Col>
                              </Row>

                          },
                      ]}
                />
            </Card>
        </div>
    )
}
export default SettingEdit