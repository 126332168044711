import { api } from "@ldcc/client/src/utils";
import { EndpointAuth } from "@ldcc/client/src/utils/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRegisterInformation } from "./index.interface";

// action
export const register = createAsyncThunk(
  'register/create',
  async (request: IRegisterInformation, thunkApi) => {
    try {
      const { data } = await api.post(EndpointAuth.register, request);
      return data;
    } catch (error: unknown) {
      return thunkApi.rejectWithValue(error);
    }
  }
);