import React from 'react';
//auth routes
import { LoginPage, RegisterPage, ForgotPasswordPage, verifyEmailPage, ResetPasswordPage, LoginTOTPPage} from '@ldcc/auth/src/pages';

//redirect from emails routes
import VerifyEmail from '../pages/auth/link/verify-email';
import LoginToken from '../pages/auth/link/login-token';
import SearchSettingsService from "@ldcc/search-service/src/pages/settings";
import { GetPermissionByProjectId, PermissionScreens, PublicRoleRoute } from 'utils';


//admin routes
const NavigatePage = React.lazy(() => import('../pages/index'));
const userProfilePage = React.lazy(() => import('@ldcc/auth/src/pages/userProfile'));
const Service = React.lazy(() => import('@ldcc/project/src/pages/api-service'));
const Project = React.lazy(() => import('@ldcc/project/src/pages/index'));
const Payments = React.lazy(() => import('@ldcc/project/src/pages/payment-transaction'));
const Payment = React.lazy(() => import('@ldcc/project/src/pages/payment-transaction-detail'));
const RefundPayments = React.lazy(() => import('@ldcc/project/src/pages/refund-payment-transactions/payment-transaction'));
const RefundPayment = React.lazy(() => import('@ldcc/project/src/pages/refund-payment-transactions/payment-transaction-detail'));
const Orders = React.lazy(() => import('@ldcc/project/src/pages/order-transaction'));
const Order = React.lazy(() => import('@ldcc/project/src/pages/order-transaction-detail'));
const ApiKeys = React.lazy(() => import('@ldcc/project/src/pages/api-keys'));
const Alert = React.lazy(() => import('@ldcc/project/src/pages/alarm-setting'));
const Home = React.lazy(() => import('@ldcc/project/src/pages/projects/projects'));
const ProjectDetail = React.lazy(() => import('@ldcc/project/src/pages/projects/projects-detail'));
const Logs = React.lazy(() => import('@ldcc/project/src/pages/access-logs/logs'));
const Delivery = React.lazy(() => import('../pages/delivery-transactions'));
const Unauthorized = React.lazy(() => import('../pages/unauthorized'));
const ReverseProxy = React.lazy(() => import('@ldcc/project/src/pages/reverse-proxy'));
const UsageReport = React.lazy(() => import('@ldcc/project/src/pages/usage-report'));
const Billings = React.lazy(() => import('@ldcc/project/src/pages/billing-reports'));
const FirmBankingTransactions = React.lazy(() => import('@ldcc/project/src/pages/firm-banking/firm-banking-transactions'));
const FirmBankingBillings = React.lazy(() => import('@ldcc/project/src/pages/firm-banking/firm-banking-billings'));
const FirmBankingTransaction = React.lazy(() => import('@ldcc/project/src/pages/firm-banking/firm-banking-transaction'));

//Search service
const Browser = React.lazy(() => import('@ldcc/search-service/src/pages/browsers'));
const BoostingRules = React.lazy(() => import('@ldcc/search-service/src/pages/rules'));
const SearchCategoriesService = React.lazy(() => import('@ldcc/search-service/src/pages/categories'));
const SearchProductsService = React.lazy(() => import('@ldcc/search-service/src/pages/products'));
const SearchVendorsService = React.lazy(() => import('@ldcc/search-service/src/pages/vendors'));
const SearchTermsService = React.lazy(() => import('@ldcc/search-service/src/pages/search-terms'))
const SynonymsService = React.lazy(() => import('@ldcc/search-service/src/pages/synonyms'))
const SearchHistoryService = React.lazy(() => import('@ldcc/search-service/src/pages/history'))
const EAVAttribute = React.lazy(() => import('@ldcc/search-service/src/pages/eav-attribute'))

//sudo
const ProjectsSudo = React.lazy(() => import('@ldcc/project/src/pages/projects/admin/projects'));
const ProjectsDetailSudo = React.lazy(() => import('@ldcc/project/src/pages/projects/admin/projects-detail'));
const ProjectMembershipSudo = React.lazy(() => import('@ldcc/project/src/pages/projects/admin/projects-membership'));
const ProjectLogsSudo = React.lazy(() => import('@ldcc/project/src/pages/access-logs/admin/projects'));
const LogsSettingSudo = React.lazy(() => import('@ldcc/project/src/pages/access-logs/admin/logs-setting'));
const LogsViewSudo = React.lazy(() => import('@ldcc/project/src/pages/access-logs/admin/logs-view'));
const UsersSudo = React.lazy(() => import('@ldcc/auth/src/pages/users/users'));
const UserSudo = React.lazy(() => import('@ldcc/auth/src/pages/users/user'));                       
const SyncDataSudo = React.lazy(() => import('@ldcc/project/src/pages/sync-data-sudo'))
interface route {
    path: string;
    component: React.FC<{}>
    allowedRoles?: string[]
}

export const publicRoutes: route[] = [
    {
        path: '/login', component: LoginPage,
    },
    {
        path: '/register', component: RegisterPage
    },
    {
        path: '/verify', component: verifyEmailPage
    },
    {
        path: '/forgot', component: ForgotPasswordPage
    },
    {
        path: '/login/totp', component: LoginTOTPPage
    },
    
]
export const redirectFromEmail: route[] = [
    {
        path: '/auth/link/verify-email', component: VerifyEmail
    },
    {
        path: '/auth/link/login-token', component: LoginToken
    },
    {
        path: '/auth/link/reset-password', component: ResetPasswordPage
    },
]

export class AdminRoute {

    constructor(role: string, projectId: string) {
        this.projectId = projectId ;
        this.role = role
    }
    private projectId: string;
    private role: string;
    private adminRoutes: route[] = [];
    private sudoRoutes: route[] = [
        {
            path: '/sudo-projects',
            component: ProjectsSudo,
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-projects/:id', 
            component: ProjectsDetailSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-projects/:id/membership', 
            component: ProjectMembershipSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-users',
            component: UsersSudo,
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-users/:id', 
            component: UserSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-logs', 
            component: ProjectLogsSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-logs/:id', 
            component: LogsSettingSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-logs/view/:id', 
            component: LogsViewSudo, 
            allowedRoles: ["*"]
        },
        {
            path: '/sudo-sync-data', 
            component: SyncDataSudo, 
            allowedRoles: ["*"]
        }
    ]
    setAdminRoutes = () => {
        this.adminRoutes = [
            {
                path: '/',
                component: NavigatePage,
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/dashboard',
                component: Project,
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/unauthorized', 
                component: Unauthorized,
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/proxy',
                component: ReverseProxy,
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_PROXY)]
            },
            {
                path: '/profile',
                component: userProfilePage,
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/services',
                component: Service,
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_PAYMENT_PROVIDER)]
            },
            {
                path: '/project',
                component: Home,
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/api-keys',
                component: ApiKeys,
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_API_KEY)]
            },
            {
                path: '/project/:id', 
                component: ProjectDetail, 
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/payment-transactions', 
                component: Payments, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_TRANSACTION)]
            },
            {
                path: '/payment-transactions/:id', 
                component: Payment, 
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/refund-payment-transactions', 
                component: RefundPayments, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_REFUND_TRANSACTION)]
            },
            {
                path: '/refund-payment-transactions/:id', 
                component: RefundPayment, 
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/order-transactions', 
                component: Orders, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_TRANSACTION)]
            },
            {
                path: '/order-transactions/:id', 
                component: Order, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_TRANSACTION)]
            },
            {
                path: '/firm-banking-billing',
                component: FirmBankingBillings, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_FIRM_BANKING_BILLINGS)]
            },
            {
                path: '/firm-banking-bank-transaction', 
                component: FirmBankingTransactions, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_FIRM_BANKING_TRANSACTIONS)]
            },
            {
                path: '/firm-banking-bank-transaction/:id', 
                component: FirmBankingTransaction, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_FIRM_BANKING_TRANSACTIONS)]
            },
            {
                path: '/search-service/browser', 
                component: Browser, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/vendors', 
                component: SearchVendorsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/alert', 
                component: Alert, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_ALERT)]
            },
            {
                path: '/billing', 
                component: Billings, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_REPORTS_BILLING)]
            },
            {
                path: '/usage-report', 
                component: UsageReport, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.READ_REPORTS_SUMMARY)]
            },
            {
                path: '/log', 
                component: Logs, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.LOGS)]
            },
            {
                path: '/delivery', 
                component: Delivery, 
                allowedRoles: [PublicRoleRoute]
            },
            {
                path: '/search-service/products', 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/products/attributes', 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/products/attributes/create', 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: `/search-service/products/attributes/:attributeId/edit`, 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/products/create', 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/products/:productId/edit', 
                component: SearchProductsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/categories', 
                component: SearchCategoriesService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },

            {
                path: '/search-service/search-terms', 
                component: SearchTermsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/synonyms', 
                component: SynonymsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/synonyms/:synonymId/edit', 
                component: SynonymsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/synonyms/create', 
                component: SynonymsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/:keySetting/edit', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/suggestion', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/indexing', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/product', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/sales', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/magento', 
                component: SearchSettingsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/settings/all', 
                component: SearchSettingsService,
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/synonyms/upload', 
                component: SynonymsService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/history', 
                component: SearchHistoryService, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path: '/search-service/rules', 
                component: BoostingRules, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            {
                path:'/search-service/eav-attribute', 
                component: EAVAttribute, 
                allowedRoles: [GetPermissionByProjectId(this.projectId, PermissionScreens.ELASTICSEARCH_READ_DOCUMENT)]
            },
            
            
        ];
        this.adminRoutes = (this.role === 'SUDO') ? [...this.adminRoutes, ...this.sudoRoutes] : this.adminRoutes
    }
    get routes() {
        this.setAdminRoutes();
        return this.adminRoutes;
    }
}
