import React, { useEffect } from "react";
import { Button, Form, Input, notification } from 'antd';
import { api } from "@ldcc/client/src/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormInputPassword } from "@ldcc/client/src/components";
import { EndpointAuth } from "@ldcc/client/src/utils/constant";

const resetPasswordContainer: React.FC = (props) => {
    
    const [loading, setLoading] = React.useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get('token');

    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    const onFinish = async (values: any) => {
        const { password } = values;
        if(!values.firstPassword || !values.password) {
            notification.error({
                message: 'Oops!',
                description: 'Information field not null',
            });
        }
        if(values.firstPassword === values.password) {
            try {
                await api.post(EndpointAuth.handleResetPassword, {
                    token,
                    password,
                    ignorePwnedPassword: true
                });
                navigate("/login");
            } catch (e) {
                notification.error({
                    message: "Oops!",
                    description: e,
                });
            }
            finally {
                setLoading(false)
            }
            
        } else {
            notification.error({
                message: 'Error!',
                description: 'Passwords is not same',
            });
        }
        
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    return (
        <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
           <Form.Item
                name="firstPassword"
                label="PassWord"
            >
                <Input.Password />
            </Form.Item>
            <FormInputPassword 
                label="Confirm Password"
                name="password"
            />
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                <Button type="primary" block htmlType="submit" loading={loading}>
                    Send
                </Button>
            </Form.Item>
        </Form>
    )
}
export default resetPasswordContainer;
