import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const PageNotFound: React.FC = () => {


    const navigate = useNavigate();
    const back = () => {
        navigate(-1)
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={back}>Back</Button>}
        />
    )
};

export default PageNotFound;