import React from "react";

import { Main} from "../components";
import {loginContainer} from "../containers"
export const LoginPage: React.FC = () => {
  return (
    <div>
      <Main container = {loginContainer} title="Login"/>
    </div>
  );
};