import { apiPrivate } from '@ldcc/client/src/utils';
import { EndpointUser } from '@ldcc/client/src/utils/constant';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IProfileUpdate, IProfileUpdateUpdateTwoFactorMethod, IProfileChangePassword } from "./index.interface";

export const profile = createAsyncThunk(
  'profile/me',
  async (userId: string, thunkApi) => {
    try {
      const response = await apiPrivate.get(`${EndpointUser.user}/${userId}`);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }

  }
);
export const profileUpdateTwoFactorMethod = createAsyncThunk(
  'profileUpdateTwoFactorMethod/update',
  async (data: IProfileUpdateUpdateTwoFactorMethod, thunkApi) => {
    try {
      const response = await apiPrivate.patch(`${EndpointUser.user}/${data.id}`,{
        twoFactorMethod: data.twoFactorMethod
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }

  }
);
export const profileTwoFactorMethodTOTPEnable = createAsyncThunk(
  'profileUpdateTwoFactorMethod/update',
  async (data: IProfileUpdateUpdateTwoFactorMethod, thunkApi) => {
    try {
      const response = await apiPrivate.patch(`${EndpointUser.user}/${data.id}`,{
        twoFactorMethod: data.twoFactorMethod
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }

  }
);
export const profileUpdate = createAsyncThunk(
  'profileUpdate/update',
  async (data: IProfileUpdate , thunkApi) =>{
    try {
      const response = await apiPrivate.patch(`${EndpointUser.user}/${data.id}`,{
        ...data
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const profileChangePassword = createAsyncThunk(
  'profileChangePassWord/update',
  async (data: IProfileChangePassword, thunkApi) =>{
    try {
      const response = await apiPrivate.patch(`${EndpointUser.user}/${data.id}`,{
        ...data
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
    
  }
);