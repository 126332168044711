import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { getEntities, getInfoEntity, getStoreByIdProject } from './index.action';
import { IInfoEntity, IStore } from './index.interface';

const entities: Array<string> = [];
const stores: Array<IStore> = [];
const entity: IInfoEntity = {};

const initialState = {
    entities: entities,
    entity: entity,
    stores: stores,
    loading: false
}
const Browsers = createSlice({
    name: "ES",
    initialState,
    reducers: {},
    extraReducers(builder) {
        // get all entities 
        builder.addCase(getEntities.pending, (state) => {
            state.entities = [];
            state.loading = true;
        });
        builder.addCase(getEntities.fulfilled, (state, action ) => {
            state.loading = false;
            state.entities = action.payload.data;
        });
        builder.addCase(getEntities.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.entities = [];
        });
        // get entity 
        builder.addCase(getInfoEntity.pending, (state) => {
            state.entity = {};
            state.loading = true;
        });
        builder.addCase(getInfoEntity.fulfilled, (state, action ) => {
            state.loading = false;
            state.entity = action.payload.data;
        });
        builder.addCase(getInfoEntity.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.entity = {};
        });
        // get stores of projectId
        builder.addCase(getStoreByIdProject.pending, (state) => {
            state.stores = [];
            state.loading = true;
        });
        builder.addCase(getStoreByIdProject.fulfilled, (state, action) => {
            state.loading = false;
            state.stores = action.payload.data.items;
        });
        builder.addCase(getStoreByIdProject.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.stores = [];
        });
    }
});

export default Browsers.reducer;