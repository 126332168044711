import React from "react";
import { Form, Input, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ldcc/client/src/redux/reducers";
import { LoginWithTOTPMultiFactor } from "../redux/actions";
import { RequestLoginTotp } from "../redux/interface";
import { InputOTP }  from 'antd-input-otp';
import { Typography } from "antd";
const { Text } = Typography;

const VerifyTOTPContainer: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');
    const dispatch = useDispatch();
    const { user, loading } = useSelector((state: RootState) => state.auth);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        const code = values?.code.join("");

        const request: RequestLoginTotp = {
            token,
            code
        }
        dispatch(LoginWithTOTPMultiFactor(request));
    };
    const handleGoBack = () => {
        navigate('/login');
    };

    React.useEffect(() => {
        if(user?.id) {
            navigate('/')
        }
    }, [dispatch, navigate, token, user?.id]);

    return (
      <Form
        name="basic"
        labelCol={{ span: 25 }}
        wrapperCol={{ span: 30 }}
        layout={'vertical'}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
          <Form.Item
            label="Please Enter TOTP Code"
            name="code"
            rules={[{ required: true, message: 'Please enter your 6-digit TOTP code!' }]}
            style={{ marginBottom: 20 }}
            extra={<Text type="warning">Check your authenticator app for the 6-digit code.</Text>}
          >
          <InputOTP
            value={null}
            autoSubmit={form}
            autoFocus={true}
            inputType="numeric"
            length={6} // Set the number of OTP inputs
          />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 15 }}>
              <Button type="primary" block htmlType="submit" loading={loading}>
                  Confirm
              </Button>
          </Form.Item>
          <Form.Item>
              <Button onClick={handleGoBack} style={{ right: 0, position: 'absolute'}} type={"link"}>
                  Go Back
              </Button>
          </Form.Item>
      </Form>
    );
}

export default VerifyTOTPContainer;
