import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSynonymsList, getCountSynonyms, deleteSynonym, getSynonym, addOrUpdateSynonym, getFileExprt, deleteAll, applySynonyms} from "../synonyms/index.action";

const initialState = {
    loading: false,
    error: null,
    synonymsList: [],
    count:null,
    synonym: null,
    exportFile:null,
};

const SynonymsSlice = createSlice({
    name: "synonyms",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getSynonymsList.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getSynonymsList.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.synonymsList = action.payload
        });
        builder.addCase(getSynonymsList.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //getCount
        builder.addCase(getCountSynonyms.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getCountSynonyms.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.count = action.payload
        });
        builder.addCase(getCountSynonyms.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //delete
        builder.addCase(deleteSynonym.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteSynonym.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(deleteSynonym.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //getSynonym
        builder.addCase(getSynonym.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getSynonym.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.synonym = action.payload
        });
        builder.addCase(getSynonym.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //addorupdate
        builder.addCase(addOrUpdateSynonym.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(addOrUpdateSynonym.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.synonym = action.payload
        });
        builder.addCase(addOrUpdateSynonym.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //export file
        builder.addCase(getFileExprt.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            state.exportFile = null;
        });
        builder.addCase(getFileExprt.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.exportFile = action.payload
        });
        builder.addCase(getFileExprt.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.exportFile = null;
        });
        //delete all -> clear
        builder.addCase(deleteAll.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteAll.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(deleteAll.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
        //apply
        builder.addCase(applySynonyms.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(applySynonyms.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
        });
        builder.addCase(applySynonyms.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        });
    }
})

export default SynonymsSlice.reducer