import { apiPrivate } from '@ldcc/client/src/utils';
import { EndpointProjects } from '@ldcc/client/src/utils/constant';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRequestTransaction } from './index.interface';

export const projectTransaction = createAsyncThunk(
    'project/me/transaction',
    async (request: IRequestTransaction, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${request.projectId}/payments/${request.id}`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const projectRefundTransaction = createAsyncThunk(
    'project/me/refund-transaction',
    async (request: IRequestTransaction, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${request.projectId}/payments/refund/${request.id}`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);

export const firmBankingTransaction = createAsyncThunk(
    'project/me/firm-banking-transaction',
    async (request: IRequestTransaction, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${request.projectId}/firm-banking/${request.id}`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);