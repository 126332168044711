import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getSuggestionSettings,
    getCountSettings,
    deleteSettings,
    getSetting,
    editSetting,
    getSettings,
    getIndexSettings, getProductSettings, getSalesSettings, getMagentoSettings
} from "./index.action";

const initialState = {
    loading: false,
    error: null,
    settings: [],
    setting: null,
    suggestions: null,
    indexing: null,
    products: null,
    sales: null,
    magento: null,
    countSettings : null,
};

const SettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {},
    extraReducers(builder) {
        // get list suggestion
        builder.addCase(getSuggestionSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.suggestions = null;
        });
        builder.addCase(getSuggestionSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.suggestions = action.payload;
        });
        builder.addCase(getSuggestionSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.suggestions = null;
        });

        // get list indexing
        builder.addCase(getIndexSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.indexing = null;
        });
        builder.addCase(getIndexSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.indexing = action.payload;
        });
        builder.addCase(getIndexSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.indexing = null;
        });

        // get list product
        builder.addCase(getProductSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.products = null;
        });
        builder.addCase(getProductSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.products = action.payload;
        });
        builder.addCase(getProductSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.products = null;
        });

        // get list sale
        builder.addCase(getSalesSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.sales = null;
        });
        builder.addCase(getSalesSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.sales = action.payload;
        });
        builder.addCase(getSalesSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.sales = null;
        });

        // get list magento
        builder.addCase(getMagentoSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.magento = null;
        });
        builder.addCase(getMagentoSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.magento = action.payload;
        });
        builder.addCase(getMagentoSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.magento = null;
        });

        //get list setting
        builder.addCase(getSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
            // @ts-ignore
            state.settings = null;
        });
        builder.addCase(getSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.settings = action.payload;
        });
        builder.addCase(getSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
            // @ts-ignore
            state.settings = null;
        });

        //count setting
        builder.addCase(getCountSettings.pending, (state, action) => {
            state.error = null;
            // @ts-ignore
            state.countSettings = null;
            state.loading = true;
        });
        builder.addCase(getCountSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = false;
            state.countSettings = action.payload;
        });
        builder.addCase(getCountSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            // @ts-ignore
            state.countSettings = null;
        });

        //delete setting
        builder.addCase(deleteSettings.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteSettings.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(deleteSettings.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
        });

        //get setting
        builder.addCase(getSetting.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getSetting.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.setting = action.payload;
        });
        builder.addCase(getSetting.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
        });

        //get setting
        builder.addCase(editSetting.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(editSetting.fulfilled, (state, action) => {
            state.error = null;
            state.loading = true;
            state.settings = action.payload;
        });
        builder.addCase(editSetting.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = true;
        });
    }
})

export default SettingsSlice.reducer