import { apiPrivate } from '@ldcc/client/src/utils';
import { EndpointProjects } from '@ldcc/client/src/utils/constant';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRequestOrder } from './index.interface';

export const projectOrderTransactions = createAsyncThunk(
    'project/me/order-transactions',
    async (request: IRequestOrder, thunkApi) => {
        try {
            const response = await apiPrivate.get(`${EndpointProjects.projects}/${request.projectId}/payments/${request.orderId}/transactions`);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
);