import { combineReducers } from "redux";
import { register, profile, auth, projectsMembership } from "@ldcc/auth/src/redux/reducers";
import { project, ProjectTransactions, ProjectTransaction, ProjectOrderTransactions, ProjectApiKeys } from "@ldcc/project/src/redux/reducers";
import {categories, attributes, productList, settings, searchTerms, synonyms, history, browsers, EAVAttributes} from '@ldcc/search-service/src/redux/reducers'
const rootReducer = combineReducers({ 
    auth,
    register, 
    profile, 
    projectsMembership, 
    project,
    ProjectTransactions,
    ProjectTransaction,
    ProjectOrderTransactions,
    ProjectApiKeys,
    categories,
    attributes,
    productList,
    settings,
    searchTerms,
    synonyms,
    history,
    browsers,
    EAVAttributes
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;