import { createSlice } from "@reduxjs/toolkit";
import { search } from "./index.action";

const initialState = {
  loading: false,
  searchFail: false,
};
const EAVAttributes = createSlice({
  name: "ES",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // builder.addCase(search.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(search.fulfilled, (state, action) => {
    //   state.loading = false;
    // });
    // builder.addCase(search.rejected, (state, action) => {
    //   state.loading = false;
    //   state.searchFail = true;
    // });
    // builder.addCase(search.pending, (state) => {
    //     state.loading = true;
    //   });
    //   builder.addCase(search.fulfilled, (state, action) => {
    //     state.loading = false;
    //   });
    //   builder.addCase(search.rejected, (state, action) => {
    //     state.loading = false;
    //     state.searchFail = true;
    //   });
  },
});

export default EAVAttributes.reducer;
