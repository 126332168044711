import React from 'react'
import { Input, Select } from "antd";
import { InputProps } from "antd/lib/input";
import type { SelectProps } from 'antd';

const Search = Input.Search;
// const selectValue: SelectProps['options'] = [];

interface Idata {
    handleSearch: (selectValue: Array<string | number>, searchText: string) => void;
    options?: SelectProps['options'];
}
export const SearchInput: React.FC<InputProps & Idata> = ({ handleSearch, options, ...rest }) => {
    const [selectValue, setSelectValue] = React.useState<Array<string>>([]);
    const onSearch = (e) => {
        const searchText = e?.target?.value || e;
        handleSearch(selectValue.length ? selectValue : [options[0].value] , searchText)
    }
    const handleChange = (value: string[]) => {
        setSelectValue(value);
    };
    return (
        <>
            <Select
                mode="multiple"
                placeholder="Field to filter"
                style={{ width: '40%', marginRight: '15px' }}
                onChange={handleChange}
                tokenSeparators={[',']}
                options={options}
            />
            <Search
                size="middle"
                onPressEnter={onSearch}
                onSearch={onSearch}
                enterButton="Search"
                placeholder="Filter text"
                {...rest}
            />
        </>

    )
}