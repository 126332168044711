import { BaseServices, EndpointProjects, EndpointUser,  } from "@ldcc/client/src/utils";
import { IProjectMemberShips } from "@ldcc/project/src/redux/interface";
import { RequestGetProjectsMembership } from "./project-membership.interface";

class ProjectsMembershipServices extends BaseServices {
    async getProjectsMembership(request: RequestGetProjectsMembership): Promise<Array<IProjectMemberShips>>{
        return (await this.axiosPrivate.get(`${this.endpoint}/${request.userId}/memberships/projects`)).data;
    }
    async getProjectsSudo(): Promise<Array<IProjectMemberShips>>{
        return (await this.axiosPrivate.get(`${EndpointProjects.p}`)).data;
    }
    getProjectsMembershipAction = this.createAsyncThunkFactory<RequestGetProjectsMembership>('me/projects/membership', this.getProjectsMembership.bind(this));
    getProjectsSudoAction = this.createAsyncThunkFactory('me/projects/sudo', this.getProjectsSudo.bind(this));
    
}

export default new ProjectsMembershipServices(EndpointUser.user)