import { BaseServices, EndpointUser } from "@ldcc/client/src/utils";
import { dataProfile } from "../../interface";
import { RequestCreateProjectForUSer, RequestDeleteProjectForUser, RequestGetUsers, RequestUpdateUser } from "./users.interface";
import { IProjectMemberShips } from "@ldcc/project/src/redux/interface";

class UsersServices extends BaseServices {
    async GetUsers(request: RequestGetUsers): Promise<Array<dataProfile>> {
        return (await this.axiosPrivate.get(`${this.endpoint}?skip=${request.skip}&take=${request.take}&where=${request.where}&orderBy=active:${request.sort}`)).data
    }
    async CountUsers(request: RequestGetUsers): Promise<number> {
        return (await this.axiosPrivate.get(`${this.endpoint}/count?where=${request.where}`)).data
    }
    async GetUser(id: number): Promise<dataProfile> {
        return (await this.axiosPrivate.get(`${this.endpoint}/${id}`)).data
    }
    async UpdateUser(request: RequestUpdateUser): Promise<dataProfile> {
        const { id, ...rest } = request;
        return (await this.axiosPrivate.patch(`${this.endpoint}/${id}`, {
            ...rest
        })).data
    }
    async DeleteUsers(id: number): Promise<Array<dataProfile>> {
        return (await this.axiosPrivate.delete(`${this.endpoint}/${id}`)).data
    }
    async CreateProjectForUser(request: RequestCreateProjectForUSer): Promise<IProjectMemberShips> {
        const { userId, ...rest } = request;
        return (await this.axiosPrivate.post(`${this.endpoint}/${userId}/memberships/project`, {
            ...rest
        })).data
    }
    async DeleteProjectForUser(request: RequestDeleteProjectForUser): Promise<IProjectMemberShips> {
        return (await this.axiosPrivate.delete(`${this.endpoint}/${request.userId}/memberships/${request.id}`)).data
    }
}
export default new UsersServices(EndpointUser.user)