import { notification } from 'antd';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dataProfile, } from "./index.interface";
import { profile, profileUpdateTwoFactorMethod, profileUpdate, profileChangePassword } from "./index.action";
import { DescriptionsNotification, MessageNotifiCation } from '@ldcc/client/src/utils/constant';
import { AuthCookies, RemoveSessionLogin } from '@ldcc/client/src/utils';

const dataProfileInit: dataProfile = {
  id: 0,
  checkLocationOnLogin: false,
  countryCode: '',
  active: false,
  gender: '',
  name: '',
  notificationEmail: '',
  prefersLanguage: '',
  prefersEmailId: 0,
  profilePictureUrl: '',
  role: '',
  timezone: '',
  twoFactorMethod: '',
  twoFactorPhone: '',
  twoFactorSecret: '',
  attributes: '',
  updatedAt: '',
  createdAt: ''
}
const initialState = {
  loading: false,
  data: dataProfileInit
}

//slice
const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //get profile information
    builder.addCase(profile.pending, (state, action) => {
      state.loading = true;
      state.data = dataProfileInit;
    });
    builder.addCase(profile.fulfilled, (state, action: PayloadAction<dataProfile>) => {
      state.loading = false;
      state.data = action.payload
    });
    builder.addCase(profile.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = dataProfileInit;
      RemoveSessionLogin()
      window.location.replace('/login');
    });
    //set two factor authentication
    builder.addCase(profileUpdateTwoFactorMethod.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(profileUpdateTwoFactorMethod.fulfilled, (state, action: PayloadAction<dataProfile>) => {
      state.loading = false;
      state.data = action.payload;
      notification.success({
        message: MessageNotifiCation.SUCCESSFULLY,
        description: DescriptionsNotification.SET_MULTI_FACTOR,
      });
    });
    builder.addCase(profileUpdateTwoFactorMethod.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    //update profile
    builder.addCase(profileUpdate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(profileUpdate.fulfilled, (state, action: PayloadAction<dataProfile>) => {
      state.loading = false;
      state.data = action.payload;
      notification.success({
        message: MessageNotifiCation.SUCCESSFULLY,
        description: DescriptionsNotification.UPDATED_PROFILE,
      });
    });
    builder.addCase(profileUpdate.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });

    //change password
    builder.addCase(profileChangePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(profileChangePassword.fulfilled, (state, action: PayloadAction<dataProfile>) => {
      state.loading = false;
      state.data = action.payload;
      notification.success({
        message: MessageNotifiCation.SUCCESSFULLY,
        description: DescriptionsNotification.UPDATED_PASSWORD,
      });

    });
    builder.addCase(profileChangePassword.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
  }
}
);

export default ProfileSlice.reducer;
