import { Layout } from "antd";
import { logout, profile } from "@ldcc/auth/src/redux/actions";
import { Sidebar, Header } from "layout";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate, useSearchParams, Route, Routes } from "react-router-dom";
import { AuthCookies, PublicRoleRoute } from "utils";
import classes from "./style.module.less";
import { AdminRoute, publicRoutes, redirectFromEmail } from "routes";
import { Loading } from "components";
import { RootState } from "redux/reducers";
import PageNotFound from "pages/page-404";
import { ProjectsMembershipServices } from '@ldcc/auth/src/redux/service'

const { Content } = Layout;

const AdminLayout: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { data } = useSelector((state: RootState) => state.profile)
    const [collapsed, setCollapsed] = React.useState(false);
    React.useEffect(() => {
        if (AuthCookies.loggedIn && data?.id === 0) {
            dispatch(profile(AuthCookies.userId));
        }

        if ((data?.id !== 0 && !!searchParams.get('projectId')) || (data?.id !== 0 && !searchParams.get('projectId'))) {
            dispatch(ProjectsMembershipServices.getProjectsMembershipAction({ userId: AuthCookies.userId }));
        }
        // if(data?.role === "SUDO") {
        //     dispatch(ProjectsMembershipServices.getProjectsSudoAction(undefined))
        // } else {
            
        // }
        // if (searchParams.get('projectId')) {
        //     dispatch(ProjectsServices.getProjectAction(searchParams.get('projectId')))
        // }
    }, [data?.id, dispatch, searchParams, AuthCookies.loggedIn]);
    const admin = new AdminRoute(data?.role, searchParams.get('projectId'));
    return (
        <Routes>
            {/* auth routes */}
            {publicRoutes.map((route, index) => {
                const Page = route.component;
                return <Route key={index} path={route.path} element={<Page />} />;
            })}
            {/* redirect from email */}
            {redirectFromEmail.map((route, index) => {
                const Page = route.component;
                return <Route key={index} path={route.path} element={<Page />} />;
            })}
            {

                admin.routes.map((route, index) => {
                    const Page = route.component;
                    const auth =
                        route.allowedRoles?.every(value => localStorage.getItem('scopes')?.split(',')?.includes(value))
                        || localStorage.getItem('scopes')?.split(',')?.includes('*')
                        || route.allowedRoles?.includes(PublicRoleRoute);
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                auth
                                    ? <Layout>
                                        <Sidebar collapsed={collapsed}/>
                                        <Layout className="site-layout" style={{
                                            backgroundColor: "#ffffffcc"
                                        }}>
                                            <Header setCollapsed={setCollapsed} collapsed={collapsed}/>
                                            <Content className={classes.container}><Suspense fallback={<Loading loading={true} />}>
                                                <Page />
                                            </Suspense></Content>
                                        </Layout>
                                    </Layout>

                                    : AuthCookies.loggedIn
                                        ? <Navigate to={`/unauthorized?projectId=${searchParams.get('projectId')}`} replace />
                                        : <Navigate to="/login" state={{ from: location }} replace />
                            }
                        />
                    );
                })
            }
            <Route path="*" element={<PageNotFound />} />
        </Routes>

    );
}

export default AdminLayout;
